import {EventEmitter, Inject, Injectable, LOCALE_ID} from '@angular/core';
import {DocumentsService as ApiDocumentsService} from '../../api/services/documents.service';
import {TrashedDocumentsService as ApiTrashedDocumentsService} from '../../api/services/trashed-documents.service';
import {MagnetTasksService as ApiMagnetTasksService} from '../../api/services/magnet-tasks.service';
import {AuthQuery} from '../../queries/auth.query';
import {DocumentStore} from '../../stores/document.store';
import {VaultsService as ApiVaultsService} from '../../api/services/vaults.service';
import {DialogService} from '../dialog/dialog.service';
import {Document} from '../../api/models/document';
import {HashMap, ID} from '@datorama/akita';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../local-file/local-file.service';
import {map, take} from 'rxjs/operators';
import {Weblink} from 'src/app/api/models/weblink';
import {VaultDocumentTypeCategory} from '../../models/vault-document-type-category';
import {VaultDocumentType} from '../../models/vault-document-type';
import {DocumentTypeCategory} from 'src/app/api/models/document-type-category';
import {DocumentType} from 'src/app/api/models/document-type';
import {DocumentTypeCategoriesService as ApiDocumentTypeCategoriesService} from '../../api/services/document-type-categories.service';
import {AssignmentCollections} from 'src/app/api/models/assignment-collections';
import {SimpleMagnetInfo} from '../../models/assignment-extended';
import {UserService} from '../user/user.service';
import {UserQuery} from '../../queries/user.query';
import {User} from 'src/app/api/models/user';
import {formatDate} from '@angular/common';
import {TaskService} from '../task/task.service';
import {NavigationService} from '../navigation/navigation.service';
import {SearchInformation} from 'src/app/api/models/search-information';
import {VaultStore} from '../../stores/vault.store';
import {CheckoutEventService} from '../electron/checkout/checkout-event.service';
import {AnnotationCollections} from 'src/app/api/models/annotation-collections';
import {DocumentViewMode} from '../../models/document-view-mode';
import {AnnotationType} from '../../types/annotation-type';
import {SinglePageAnnotationData} from 'src/app/api/models/single-page-annotation-data';
import {SelectedItemDescription} from '../../models/selected-item-description-type';
import {AnnotationService} from '../annotation/annotation.service';
import {Filesystem} from '@capacitor/filesystem';
import {getMobileRootDirectory} from '../../util/mobile-root-directory';
import {arrayBufferToBase64} from '../../util/arraybuffer-to-base64';
import {DocumentAssignedToMagnetCondition} from '../../api/models/document-assigned-to-magnet-condition';
import {Vector2D} from '../../models/vector-2d';
import {downloadBlob} from '../../util/download-blob';
import {environment} from '../../../environments/environment';
import {ListService} from '../list/list.service';
import {PermissionService} from '../permission/permission.service';
import {DocumentQuery} from '../../queries/document.query';
import {Router} from '@angular/router';
import {PermissionQuery} from '../../queries/permission.query';
import {getLocationHeader} from '../../util/get-location-header';
import {WeblinksService as ApiWeblinksService} from '../../api/services/weblinks.service';
import {BrowserQuery} from '../../queries/browser.query';
import {PLATFORMS} from '../../constants/device';
import {HttpErrorResponse} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {FileOpener} from '@capacitor-community/file-opener';
import {STATIC_CONFIGS} from '../../../configs/static.config';

// eslint-disable-next-line @typescript-eslint/no-require-imports
const mimetypes = require('../../util/mimetypes');

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    refreshCurrentViewEvent: EventEmitter<void>;

    constructor(
        private apiDocumentsService: ApiDocumentsService,
        private apiVaultsService: ApiVaultsService,
        private apiTrashedDocumentService: ApiTrashedDocumentsService,
        private apiMagnetTasksService: ApiMagnetTasksService,
        private documentStore: DocumentStore,
        private authQuery: AuthQuery,
        private dialogService: DialogService,
        private apiDocumentTypeCategoriesService: ApiDocumentTypeCategoriesService,
        @Inject(LOCAL_FILE_SERVICE) private localFileService: LocalFileService,
        private userService: UserService,
        private userQuery: UserQuery,
        private taskService: TaskService,
        private navigationService: NavigationService,
        private vaultStore: VaultStore,
        private listService: ListService,
        @Inject(LOCALE_ID) private localeId: string,
        private checkoutEventService: CheckoutEventService,
        private annotationService: AnnotationService,
        private permissionService: PermissionService,
        private documentQuery: DocumentQuery,
        private router: Router,
        private permissionQuery: PermissionQuery,
        private apiWeblinksService: ApiWeblinksService,
        private browserQuery: BrowserQuery,
    ) {
        this.refreshCurrentViewEvent = new EventEmitter<void>(undefined);
        this.checkoutEventService.removeActiveAndSelectNextAvailableDocumentEvent.subscribe((documentId) => {
            this.removeActiveDocumentAndSelectNextAvailable(documentId)
                .then();
        });
    }

    public async fetchDocument(documentId: string): Promise<void> {
        await this.fetchAndGetDocument(documentId);
    }

    public async fetchAndGetDocument(documentId: string, upsertDocument: boolean = true): Promise<Document | undefined> {
        this.setLoading(true);
        let document: Document | undefined;
        try {
            document = await firstValueFrom(this.apiDocumentsService.DocumentsGet({
                documentId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            if (upsertDocument) {
                this.upsertDocument(document);
            }
        } catch (err) {
            console.error(err);
            this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG');
        } finally {
            this.setLoading(false);
        }
        return document;
    }

    public async getDocument(documentId: string): Promise<Document | undefined> {
        const entities: HashMap<Document> | undefined = this.documentStore.getValue().entities;
        if (entities && entities[documentId]) {
            return entities[documentId];
        } else {
            return this.fetchAndGetDocument(documentId);
        }
    }

    public async removeActiveDocumentAndSelectNextAvailable(documentId: string, setDeletedFlag: boolean = false): Promise<void> {
        const list = await this.listService.getLatestList();
        if (list) {
            if (!list.hasLoadedData()) {
                await list.fetchData(0);
            }
            if (setDeletedFlag) {
                list.setItemDeleted(documentId);
            }
            const nextId = await list.getNextId(documentId);
            if (nextId) {
                const url = this.router.url;
                const urlParts = url.split('/');
                urlParts[urlParts.length - 1] = nextId;
                this.navigationService.navigate(urlParts.join('/'), {
                        replaceUrl: true
                    }, true)
                    .then();
                return;
            }
        }
        this.navigateToHome();
    }

    public navigateToHome() {
        const activeVaultId = this.vaultStore.getValue().active as string;
        this.navigationService.navigate(['vaults', 'detail', activeVaultId])
            .then();
    }

    public async deleteDocument(documentId: string): Promise<boolean> {
        let isDeleted = false;
        const confirm = await this.dialogService.showConfirmDialog(
            {
                messageKey: 'DOCUMENT_DELETE_CONFIRM_MESSAGE',
                title: 'DOCUMENT_DELETE_CONFIRM_TITLE',
                confirmText: 'BUTTON.DELETE',
                cancelText: 'BUTTON.CANCEL',
                appTestTag: 'delete-document',
            });
        if (!confirm) {
            return isDeleted;
        }
        this.setLoading(true);
        try {
            // preloading the list, to correctly display the list on after changing from fullpage view
            const list = await this.listService.getLatestList();
            if (list) {
                if (!list.hasLoadedData()) {
                    await list.fetchData(0);
                }
            }
            await firstValueFrom(this.apiDocumentsService.DocumentsTrash({
                documentId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            this.documentStore.update(documentId, { visibilityScope: 'Trashed' });
            this.removeActiveDocumentAndSelectNextAvailable(documentId, true)
                .then();
            isDeleted = true;
            this.dialogService.showInfo('SUCCESS_DELETE_DOCUMENT');
            // TODO remove when websockets available.
            setTimeout(async () => {
                await this.fetchVaultPermissions();
                this.taskService.checkForOpenTasks()
                    .then();
            }, 10000);
        } catch (err) {
            console.error(err);
            this.dialogService.showError('ERROR.DOCUMENTS_DELETE_MSG');
            isDeleted = false;
        } finally {
            this.setLoading(false);
        }
        return isDeleted;
    }

    public async deleteDocumentFinally(documentId: string): Promise<boolean> {
        let isDeleted = false;
        const confirm = await this.dialogService.showConfirmDialog(
            {
                messageKey: 'DOCUMENT_DELETE_PERMANENTLY_CONFIRM_MESSAGE',
                title: 'DOCUMENT_DELETE_PERMANENTLY_CONFIRM_TITLE',
                confirmText: 'BUTTON.DELETE_PERMANENTLY',
                cancelText: 'BUTTON.CANCEL',
                appTestTag: 'delete-document-finally',
            });
        if (!confirm) {
            return isDeleted;
        }
        this.setLoading(true);
        try {
            await firstValueFrom(this.apiTrashedDocumentService.TrashedDocumentsDelete({
                documentId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            await this.removeActiveDocumentAndSelectNextAvailable(documentId);
            const list = await this.listService.getLatestList();
            if (list) {
                list.setItemDeleted(documentId);
            }
            isDeleted = true;
            this.dialogService.showInfo('SUCCESS_FINAL_DELETE_DOCUMENT');
        } catch (err) {
            console.error(err);
            this.dialogService.showError('ERROR.DOCUMENTS_DELETE_MSG');
            isDeleted = false;
        } finally {
            this.setLoading(false);
        }
        return isDeleted;
    }

    public async restoreDocument(documentId: string): Promise<boolean> {
        this.setLoading(true);
        let isRestored = false;
        try {
            await firstValueFrom(this.apiTrashedDocumentService.TrashedDocumentsRestore({
                documentId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            this.documentStore.update(documentId, { visibilityScope: 'Normal' });
            const list = await this.listService.getLatestList();
            if (list) {
                list.unsetItemDeleted(documentId);
                list.markItem(documentId);
            }
            this.removeActiveDocumentAndSelectNextAvailable(documentId)
                .then();
            isRestored = true;
            this.dialogService.showInfo('SUCCESS_RESTORE_DOCUMENT');
            await this.fetchVaultPermissions();
        } catch (err) {
            console.error(err);
            this.dialogService.showError('ERROR.DOCUMENTS_RESTORE_MSG');
            isRestored = false;
        } finally {
            this.setLoading(false);
        }
        return isRestored;
    }

    public async fetchAndGetDocumentAssignments(documentId: string): Promise<AssignmentCollections | undefined> {
        let result: AssignmentCollections | undefined;
        try {
            result = await firstValueFrom(this.apiDocumentsService.DocumentsGetAssigns({
                documentId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (err) {
            console.error(err);
        }
        return result;
    }

    public async fetchAndStoreDocumentAssignments(documentId: string): Promise<void> {
        try {
            const result: AssignmentCollections = await this.fetchAndGetDocumentAssignments(documentId) as AssignmentCollections;

            if (result) {
                const promises = [...result.mergeDefinedAssignments, ...result.userDefinedAssignments, ...result.systemDefinedAssignments]
                    .map(async assignment => {
                        const isChild = documentId === assignment.childDocId;
                        const referenceDocId = (isChild) ? assignment.parentDocId : assignment.childDocId;
                        const document = await this.getDocumentById(referenceDocId);

                        return {
                            ...assignment,
                            isChild,
                            documentName: document.name,
                            documentId: referenceDocId,
                            iconId: document.iconId,
                            iconUrl: '/' + STATIC_CONFIGS.paths.icons + '/' + document.iconId + '?size=Medium',
                        };
                    });
                const documents = await Promise.all(promises);
                documents.sort((firstDoc, secondDoc) => firstDoc.documentName.localeCompare(secondDoc.documentName));

                const magnets: Array<SimpleMagnetInfo> = [
                    ...result.attachingMagnets.map(m => {
                        return { id: m.id, name: m.name };
                    }),
                    ...result.anchoringMagnetAssignments.map(m => {
                        return { id: m.magnetId, name: m.magnetName };
                    })
                ].sort((firstMagnet, secondMagnet) => firstMagnet.name.localeCompare(secondMagnet.name));

                this.documentStore.update({
                    documentAssignment: {
                        documents,
                        magnets,
                    }

                });
            }
        } catch (err) {
            console.error(err);
            this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG');
        }
    }

    public resetDocumentAssignments(): void {
        this.documentStore.update({
            documentAssignment: undefined
        });
    }

    public async getDocumentCheckOutData(document: Document): Promise<{ isSameUser: boolean; checkOutByUser: User | undefined; since: string }> {
        const userId = this.authQuery.getUserId();
        const documentCheckedOutUserId = document.checkedOutByUserId as string;
        const isSameUser = document.checkedOutByUserId === userId;
        let checkOutByUser = this.userQuery.getUserById(documentCheckedOutUserId);
        if (checkOutByUser === undefined) {
            await this.userService.fetchUser(documentCheckedOutUserId);
            checkOutByUser = this.userQuery.getUserById(documentCheckedOutUserId);
        }
        const since = formatDate(document.checkedOutSince as string, 'dd.MM.yyyy HH:mm', this.localeId);
        return {
            checkOutByUser,
            isSameUser,
            since
        };
    }

    public async updateDocumentsOfVault(vaultId: string): Promise<void> {
        this.setLoading(true);
        try {
            const documents = await firstValueFrom(this.apiVaultsService.VaultsGetDocuments({
                vaultId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                count: 999
            }));

            this.documentStore.upsertMany(documents);
            this.documentStore.setActive(documents.map(doc => doc.id as ID));

        } catch (err) {
            this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG', err as Error);
        } finally {
            this.setLoading(false);
        }
    }

    public async fetchTrashedDocuments(vaultId: string, offset: number = 0, limit: number = 999): Promise<Array<Document>> {
        this.setLoading(true);
        let documents: Array<Document> = [];
        try {
            documents = await firstValueFrom(this.apiVaultsService.VaultsGetTrashedDocuments({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                vaultId,
                count: limit,
                offset,
            }));
            this.documentStore.upsertMany(documents);
            this.documentStore.setActive(documents.map(doc => doc.id as ID));
        } catch (err) {
            this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG', err as Error);
        } finally {
            this.setLoading(false);
        }
        return documents;
    }

    public async fetchClassificationDocuments(vaultId: string, offset: number = 0, limit: number = 999): Promise<Array<Document>> {
        this.setLoading(true);
        let documents: Array<Document> = [];
        try {
            documents = await firstValueFrom(this.apiVaultsService.VaultsGetClassificationDocuments({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                vaultId,
                count: limit,
                offset,
            }));
            this.documentStore.upsertMany(documents);
            this.documentStore.setActive(documents.map(doc => doc.id as ID));
        } catch (err) {
            this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG', err as Error);
        } finally {
            this.setLoading(false);
        }
        return documents;
    }

    public async fetchMagnetTasksDocuments(taskId: string, offset = 0, count = 40): Promise<Array<Document>> {
        this.setLoading(true);
        let documents: Array<Document> = [];
        try {
            documents = await firstValueFrom(this.apiMagnetTasksService.MagnetTasksGetDocuments({
                taskId,
                offset,
                count,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            this.documentStore.upsertMany(documents);
            this.documentStore.setActive(documents.map(doc => doc.id as ID));
        } catch (err) {
            this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG', err as Error);
        } finally {
            this.setLoading(false);
        }
        return documents;
    }

    public async openArrayBufferFile(buffer: ArrayBuffer, fileName: string): Promise<void> {
        const file = new Blob([buffer], { type: 'octet/stream' });
        if (this.browserQuery.getPlatform() === PLATFORMS.ELECTRON) {
            return this.localFileService.saveFile(buffer, fileName, true);
            /*this.electronService.ipcRenderer.once(IPC.FILE.CREATE.RESULT, (event, args: { createdResult: boolean; path: string }) => {
                const result = args.valueOf() as { result: boolean; path: string };
                if (result.result) {
                    this.electronService.ipcRenderer.send(IPC.FILE.OPEN.REQUEST, result.path);
                }
            });
            this.electronService.ipcRenderer.send(IPC.FILE.CREATE.REQUEST, {
                filePath: fileName,
                content: buffer,
                openFile: true
            });*/
        }

        switch (this.browserQuery.getPlatform()) {
            case PLATFORMS.WEB:
                downloadBlob(file, fileName);
                break;
            case PLATFORMS.ANDROID:
                console.warn('use android-local-file syncFileToDevice');
                break;
            default:
                const result = await Filesystem.writeFile({
                    path: fileName,
                    directory: getMobileRootDirectory(),
                    data: arrayBufferToBase64(buffer),
                });
                FileOpener.open({
                        filePath: result.uri,
                        contentType: mimetypes.getMimeTypeForFilename(fileName),
                        openWithDefault: false,
                    })
                    // eslint-disable-next-line prefer-rest-params
                    .then(() => console.log(arguments, fileName, 'File is opened'), (err: any) => {
                        console.error(err);
                        this.dialogService.showError('FILE_OPEN_ERROR_MSG', err, { fileName });
                    });
                break;
        }
    }

    /** @deprecated use document id from url instead **/
    public async setSelectedDocument(documentId: string, forceFetch: boolean = false): Promise<void> {
        const entities = this.documentStore.getValue().entities || [];
        if (!(documentId in entities) || forceFetch) {
            await this.fetchDocument(documentId);
        }
        this.documentStore.update({ selected: documentId });
        if (!this.permissionQuery.hasDocumentPermissionLoaded(documentId)) {
            await this.permissionService.fetchDocumentPermission(documentId);
        }
        const selectedDocument = this.documentQuery.getSelectedDocument();
        if (selectedDocument?.checkedOut && this.permissionQuery.hasDocumentPermission(documentId, 'DocumentsCheckOut')) {
            await this.permissionService.fetchCheckedOutDocumentPermission(documentId);
        }
        if (selectedDocument?.visibilityScope === 'Trashed') {
            await this.permissionService.fetchTrashedDocumentPermission(documentId);
        }
        this.annotationService.fetchStampsForDocument(documentId)
            .then();
    }

    /** @deprecated use document id from url instead **/
    public unsetSelectedDocuments(): void {
        this.documentStore.update({
            selected: '',
            documentAssignment: undefined
        });
    }

    public setLoading(loading: boolean): void {
        this.documentStore.setLoading(loading);
    }

    public async clearDocumentAssignments(): Promise<void> {
        this.documentStore.update({ documentAssignment: undefined });
    }

    public async setActiveDocuments(documentIds: Array<string>): Promise<void> {
        this.documentStore.setActive(documentIds);
    }

    public async fetchDocumentWeblinks(documentId: string): Promise<Array<Weblink>> {
        return await firstValueFrom(this.apiDocumentsService.DocumentsGetWeblinks({
            documentId,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: this.authQuery.getBearer()
        }));
    }

    public setCurrentPreviewPage(page: number): void {
        this.documentStore.update({ currentPreviewPage: page });
    }

    public async createWeblink(documentId: string): Promise<Weblink | undefined> {
        return firstValueFrom(this.apiDocumentsService.DocumentsCreateWeblinkForDocument({
                documentId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer(),
                model: {
                    fileType: 'Original',
                    validity: 'Permanent',
                    viewType: 'Download'
                }
            })
            .pipe(
                getLocationHeader(),
                map(newWebLinkIdUrl => {
                    if (newWebLinkIdUrl) {
                        const newWebLinkId = (newWebLinkIdUrl as string).split('/')
                            .pop() as string;
                        return this.getWeblink(newWebLinkId);
                    }
                    return undefined;
                })
            ));
    }

    public async getWeblink(weblinkId: string): Promise<Weblink | undefined> {
        try {
            return await firstValueFrom(this.apiWeblinksService.WeblinksGetWeblink({
                    weblinkId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: this.authQuery.getBearer(),
                })
                .pipe(take(1)));
        } catch (e) {
            console.error(e);
        }
        return undefined;
    }

    public async fetchDocumentTypeCategories(): Promise<void> {
        try {
            const vaultId: string | null = this.vaultStore.getValue().active;
            if (vaultId) {
                const currentDocumentTypeCategories: Array<VaultDocumentTypeCategory> = this.documentStore.getValue().documentTypeCategories;
                const filteredDocumentTypeCategories: Array<VaultDocumentTypeCategory> = currentDocumentTypeCategories.filter(d => d.vaultId === vaultId);

                if (filteredDocumentTypeCategories.length === 0) {
                    const documentTypeCategoriesResult: Array<VaultDocumentTypeCategory> = await firstValueFrom(this.apiVaultsService.VaultsGetDocumentTypeCategories({
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            Authorization: this.authQuery.getBearer(),
                            vaultId
                        })
                        .pipe(map((documentTypeCategories: Array<DocumentTypeCategory>) => {
                            return documentTypeCategories.map((documentTypeCategory: DocumentTypeCategory) => {
                                const vaultDocumentTypeCategory = documentTypeCategory as VaultDocumentTypeCategory;
                                vaultDocumentTypeCategory.vaultId = vaultId;
                                return vaultDocumentTypeCategory;
                            });
                        })));
                    this.documentStore.update({ documentTypeCategories: [...currentDocumentTypeCategories, ...documentTypeCategoriesResult] });
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    public async fetchDocumentTypes(): Promise<void> {
        try {
            const vaultId: string | null = this.vaultStore.getValue().active;
            if (vaultId) {
                const currentDocumentTypeCategories: Array<VaultDocumentTypeCategory> = this.documentStore.getValue().documentTypeCategories;
                const documentTypeCategoryIds: Array<string> = currentDocumentTypeCategories.filter(d => d.vaultId === vaultId)
                    .map(d => d.id);

                if (documentTypeCategoryIds.length > 0) {
                    const currentDocumentTypes: Array<VaultDocumentType> = this.documentStore.getValue().documentTypes;
                    const filteredDocumentTypes: Array<VaultDocumentType> = currentDocumentTypes.filter(d => d.vaultId === vaultId);
                    if (filteredDocumentTypes.length === 0) {
                        // eslint-disable-next-line prefer-spread
                        const documentTypesResult: Array<VaultDocumentType> = [].concat.apply([], await Promise.all(documentTypeCategoryIds.map(documentTypeCategoryId => {
                            return firstValueFrom(this.apiDocumentTypeCategoriesService.DocumentTypeCategoriesGetDocumentTypes({
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    Authorization: this.authQuery.getBearer(),
                                    documentTypeCategoryId
                                })
                                .pipe(map((documentTypes: Array<DocumentType>): Array<VaultDocumentType> => {
                                    return documentTypes.map((documentType: DocumentType) => {
                                        const vaultDocumentType = documentType as VaultDocumentType;
                                        vaultDocumentType.vaultId = vaultId;
                                        return vaultDocumentType;
                                    });
                                })));
                        }) as Array<Promise<any>>));
                        this.documentStore.update({ documentTypes: [...currentDocumentTypes, ...documentTypesResult] });
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    public async searchVaultTopLevelDocuments(vaultId: any): Promise<SearchInformation | undefined> {
        let searchInformation;
        try {
            searchInformation = await firstValueFrom(this.apiVaultsService.VaultsInitTopLevelDocumentsSearch({
                vaultId,
                sortInformation: {
                    order: 'Descending',
                    tagDefinitionId: '2B39B0F8-D8CA-4DE1-B96A-A55E738FB103'
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (err) {
            this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG', err as Error);
        }
        return searchInformation;
    }

    public async fetchVaultDocuments(searchId: string, offset: number, count: number): Promise<Array<Document>> {
        this.setLoading(true);
        let documents: Array<Document> = [];
        try {
            documents = await firstValueFrom(this.apiDocumentsService.DocumentsGetSearchResults({
                searchId,
                offset,
                count,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (error) {
            // Don't throw error code -> just recreate the search
            const httpError = error as HttpErrorResponse;
            if (!(error && httpError.error && httpError.error.code === 7000)) {
                this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG', error as Error);
            }
        } finally {
            this.setLoading(false);
        }
        return documents;
    }

    public async searchMagnetDocuments(vaultId: string, magnetId: string): Promise<SearchInformation | undefined> {
        let searchInformation;
        try {
            searchInformation = await firstValueFrom(this.apiDocumentsService.DocumentsInitAdvancedSearch({
                filter: {
                    vaultIds: [vaultId],
                    condition:
                        {
                            type: 'DocumentAssignedToMagnetCondition',
                            magnetId,
                            affinityFilter: 'Any',
                            descendantAssignmentFilter: 'IsAssignedDirectButNotByDescendants'
                        } as DocumentAssignedToMagnetCondition,
                    sortInformation: {
                        order: 'Descending',
                        tagDefinitionId: '2B39B0F8-D8CA-4DE1-B96A-A55E738FB103'
                    }
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (err) {
            this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG', err as Error);
        }
        return searchInformation;
    }

    public async fetchMagnetDocuments(searchId: string, offset: number, count: number): Promise<Array<Document>> {
        this.setLoading(true);
        let documents: Array<Document> = [];
        try {
            documents = await firstValueFrom(this.apiDocumentsService.DocumentsGetSearchResults({
                searchId,
                offset,
                count,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (error) {
            // Don't throw error code -> just recreate the search
            const httpError = error as HttpErrorResponse;
            if (!(error && httpError.error && httpError.error.code === 7000)) {
                this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG', error as Error);
            }
        } finally {
            this.setLoading(false);
        }
        return documents;
    }

    public async fetchDocumentAnnotations(documentId: string, page: number): Promise<void> {
        this.setLoading(true);
        try {
            const currentAnnotations = this.documentStore.getValue().annotations;
            let annotations: Array<AnnotationCollections> = [];
            if (currentAnnotations) {
                annotations = [...currentAnnotations];
            }

            const annotationsOfPage: AnnotationCollections | undefined = await firstValueFrom(this.apiDocumentsService.DocumentsGetAnnotations({
                documentId,
                pageNo: page,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            if (annotationsOfPage) {
                annotations[page - 1] = annotationsOfPage;
            } else {
                delete annotations[page - 1];
            }

            this.documentStore.update({
                annotations
            });
        } catch (err) {
            this.dialogService.showError('ANNOTATION.LOADING_ERROR_MSG', err as Error);
        } finally {
            this.setLoading(false);
        }

    }

    public resetAnnotations(): void {
        this.documentStore.update({
            annotations: [],
            temporaryAnnotations: undefined
        });
    }

    public setTemporaryAnnotations(temporaryAnnotations: AnnotationCollections | undefined): void {
        this.documentStore.update({
            temporaryAnnotations
        });
    }

    public setDocumentViewMode(documentViewMode: DocumentViewMode): void {
        this.documentStore.update({ documentViewMode });
    }

    public setSelectedAnnotation(selectedAnnotation: SelectedItemDescription | undefined): void {
        this.documentStore.update({ selectedAnnotation });
    }

    public setDrawAnnotationType(drawAnnotationType: AnnotationType | undefined): void {
        this.documentStore.update({
            drawAnnotationType
        });
    }

    public async saveAnnotations(documentId: string, singlePageAnnotationData: SinglePageAnnotationData): Promise<boolean> {
        this.setLoading(true);
        let hasError = false;
        try {
            await firstValueFrom(this.apiDocumentsService.DocumentsSaveAnnotations({
                documentId,
                singlePageAnnotationData,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (err) {
            this.dialogService.showError('ANNOTATION.SAVING_ERROR_MSG', err as Error);
            hasError = true;
        } finally {
            this.setLoading(false);
        }

        return hasError;
    }

    public async waitForDocumentToBeReady(documentId: string, retries: number = 99999999, intervalInSeconds: number = 1000): Promise<Document | undefined> {
        let document: Document | undefined;
        await new Promise<void>(async resolve => {
            const getDocument = async () => {
                document = await this.fetchAndGetDocument(documentId, false);
                const changed = document?.state === 'Ready';
                --retries;
                if (retries > 0 && !changed) {
                    window.setTimeout(getDocument, intervalInSeconds);
                } else {
                    this.upsertDocument(document as Document);
                    resolve();
                }
            };

            await getDocument();
        });
        return document && document.state === 'Ready' ? document : undefined;
    };

    public async searchTaskDocuments(activeMagnetTaskId: string): Promise<SearchInformation | undefined> {
        let searchInformation;
        try {
            searchInformation = await firstValueFrom(this.apiDocumentsService.DocumentsInitAdvancedSearch({
                filter: {
                    condition: {
                        type: 'DocumentAssignedToMagnetCondition',
                        magnetId: activeMagnetTaskId,
                        affinityFilter: 'Dynamic',
                        descendantAssignmentFilter: 'IsAssignedDirect'
                    } as DocumentAssignedToMagnetCondition,
                    sortInformation: {
                        order: 'Descending',
                        tagDefinitionId: '2B39B0F8-D8CA-4DE1-B96A-A55E738FB103'
                    }
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
        } catch (err) {
            this.dialogService.showError('DOCUMENTS_LOADING_ERROR_MSG', err as Error);
        }
        return searchInformation;
    }

    public async openDocumentViaDeepLink(url: string | undefined): Promise<void> {
        console.log('amagno deep link', url);
        if (url && url.startsWith(environment.deepLinkPrefix)) {
            const guid = url.split('/')[2];

            if (guid) {
                const document = await this.fetchAndGetDocument(guid);
                this.navigationService.navigate(['magnets', 'vault', document?.vaultId, 'document', document?.id])
                    .then();
            }
        }
    }

    public setImageSize(imageSize: Vector2D): void {
        this.documentStore.update({
            imageSize
        });
    }

    private upsertDocument(document: Document): void {
        this.documentStore.upsert(document.id, document);
    }

    /**
     * Only for convenience in this class
     *
     * @param documentId
     * @private
     */
    private async getDocumentById(documentId: string): Promise<Document> {
        let document = {} as Document;
        const store = this.documentStore.getValue();
        if (store &&
            store.entities &&
            store.entities.hasOwnProperty(documentId)) {
            document = store.entities[documentId];
        } else {
            // query document
            await this.fetchDocument(documentId);
            const updatedStore = this.documentStore.getValue();
            if (updatedStore &&
                updatedStore.entities &&
                updatedStore.entities.hasOwnProperty(documentId)) {
                document = updatedStore.entities[documentId];
            }
        }
        return document;
    }

    private async fetchVaultPermissions() {
        const activeVaultId = this.vaultStore.getValue().active;

        if (activeVaultId) {
            await this.permissionService.fetchVaultPermissions(activeVaultId, true);
        }
    }
}
