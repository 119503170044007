import {CommonModule} from '@angular/common';
import {Injector, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {RouterModule} from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {VaultSelectorDialogComponent} from '../components/dialogs/vault-selector-dialog/vault-selector-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {LoadingSpinnerComponent} from '../components/loading-spinner/loading-spinner.component';
import {AuthImagePipe} from '../pipes/auth-image/auth-image.pipe';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TbFirstRowDirective, TbObjectIconLeftDirective, TbObjectIconRightDirective, ToolbarComponent} from '../components/toolbar/toolbar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ToolbarTabletComponent} from '../components/toolbar-tablet/toolbar-tablet.component';
import {VaultsComponent} from '../components/vaults/vaults.component';
import {FavoriteListComponent} from '../components/favorite-list/favorite-list.component';
import {ContactsComponent} from '../components/contacts/contacts.component';
import {ToolbarTabsComponent} from '../components/toolbar-tabs/toolbar-tabs.component';
import {FastAccessMenuComponent} from '../components/fast-access-menu/fast-access-menu.component';
import {CustomIconComponent} from '../components/custom-icons/custom-icon.component';
import {DocumentListComponent} from '../components/document-list/document-list.component';
import {VaultAdministrationComponent} from '../components/vault-administration/vault-administration.component';
import {DocumentsDashboardComponent} from '../components/documents-dashboard/documents-dashboard.component';
import {MatListModule} from '@angular/material/list';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ListDragDownDirective} from '../directives/list-drag-down.directive';
import {BaseDocumentPreviewComponent} from '../components/base-document-preview/base-document-preview.component';
import {NavigateToDirective} from '../directives/navigate-to.directive';
import {MagnetsComponent} from '../components/magnets/magnets.component';
import {MagnetDocumentListComponent} from '../components/magnet-document-list/magnet-document-list.component';
import {InProgressListComponent} from '../components/in-progress-list/in-progress-list.component';
import {TasksDashboardComponent} from '../components/tasks-dashboard/tasks-dashboard.component';
import {BaseTaskComponent} from '../components/base-task/base-task.component';
import {ImageInViewDirective} from '../directives/image-in-view.directive';
import {SearchDashboardComponent} from '../components/search-dashboard/search-dashboard.component';
import {SearchResultVaultListComponent} from '../components/search-result-vault-list/search-result-vault-list.component';
import {SearchOverviewComponent} from '../pages/layout-tablet/search/search-overview/search-overview.component';
import {DragExportDirective} from '../directives/drag-export.directive';
import {FloatingActionButtonComponent} from '../components/floating-action-button/floating-action-button.component';
import {CardComponent} from '../components/card/card.component';
import {ActionItemGroupComponent} from '../components/action-item-group/action-item-group.component';
import {ActionItemComponent} from '../components/action-item/action-item.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {TagBaseCardComponent} from '../components/card/tag-card/tag-base-card.component';
import {SeeAlsoCardComponent} from '../components/card/see-also-card/see-also-card.component';
import {BaseTaskDocumentPreviewComponent} from '../components/base-task-document-preview/base-task-document-preview.component';
import {ShareCardComponent} from '../components/card/share-card/share-card.component';
import {NotImplementedCardComponent} from '../components/card/not-implemented-card/not-implemented-card.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {OpenInBrowserDirective} from '../directives/open-in-browser.directive';
import {ContentCardButtonsDirective, ContentCardComponent} from '../components/card/content-card/content-card.component';
import {MatSliderModule} from '@angular/material/slider';
import {BetterEventsDirective} from '../directives/better-events.directive';
import {FindingsCardComponent} from '../components/card/findings-card/findings-card.component';
import {RangeSliderComponent} from '../components/range-slider/range-slider.component';
import {ContactDetailComponent} from '../components/contact-detail/contact-detail.component';
import {DocumentTagsCardComponent} from '../components/card/tag-card/document-tags-card/document-tags-card.component';
import {VaultTagsCardComponent} from '../components/card/tag-card/vault-tags-card/vault-tags-card.component';
import {MagnetTagsCardComponent} from '../components/card/tag-card/magnet-tags-card/magnet-tags-card.component';
import {ContactTagsCardComponent} from '../components/card/tag-card/contact-tags-card/contact-tags-card.component';
import {ListViewCardComponent} from '../components/card/list-view-card/list-view-card.component';
import {InViewDirective} from '../directives/in-view.directive';
import {DocumentListStateIconsComponent} from '../components/document-list-state-icons/document-list-state-icons.component';
import {DocumentFilenameComponent} from '../components/document-filename/document-filename.component';
import {ImportDialogComponent} from '../components/dialogs/import-dialog/import-dialog.component';
import {DropImportDirective} from '../directives/drop-import.directive';
import {ToastContentComponent} from '../components/toast-content/toast-content.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ImportFormDialogComponent} from '../components/dialogs/import-dialog/import-form-dialog/import-form-dialog.component';
import {AddMagnetCardComponent} from '../components/card/add-magnet-card/add-magnet-card.component';
import {AddVaultCardComponent} from '../components/card/add-vault-card/add-vault-card.component';
import {FilteredListPaginationComponent} from '../components/list-components/filtered-list-pagination/filtered-list-pagination.component';
import {SanitizeUrlPipe} from '../pipes/sanitizer/sanitize-url.pipe';
import {DocumentViewComponent} from '../components/document-view/document-view.component';
import {DocumentViewDrawableComponent} from '../components/document-view/document-view-drawable/document-view-drawable.component';
import {DocumentViewTextItemsComponent} from '../components/document-view/document-view-text-items/document-view-text-items.component';
import {AnnotationsLayerDirective} from '../directives/annotations-layer.directive';
import {AnnotationCardComponent} from '../components/card/annotation-card/annotation-card.component';
import {DocumentViewAnnotationsComponent} from '../components/document-view/document-view-annotations/document-view-annotations.component';
import {VaultIdToNamePipe} from '../pipes/vault-id-to-name/vault-id-to-name.pipe';
import {SearchNoResultContentComponent} from '../components/search-no-result-content/search-no-result-content.component';
import {ExtAsyncPipe} from '../pipes/ext-async/ext-async.pipe';
import {TasksVaultListComponent} from '../components/tasks-vault-list/tasks-vault-list.component';
import {ListItemTextWithCounterComponent} from '../components/list-item-text-with-counter/list-item-text-with-counter.component';
import {TaskMagnetTaskDocumentListComponent} from '../components/task-magnet-task-document-list/task-magnet-task-document-list.component';
import {StampCardComponent} from '../components/card/stamp-card/stamp-card.component';
import {ActionMenuDocumentPreviewComponent} from '../components/action-menu-document-preview/action-menu-document-preview.component';
import {ClickFinderListComponent} from '../components/click-finder/click-finder-list/click-finder-list.component';
import {ClickFinderDocumentsComponent} from '../components/click-finder/click-finder-documents/click-finder-documents.component';
import {HistoryButtonComponent} from '../components/history-button/history-button.component';
import {HistoryOverlayComponent} from '../components/history-overlay/history-overlay.component';
import {HistoryDashboardComponent} from '../components/history-dashboard/history-dashboard.component';
import {ClickDirective} from '../directives/click.directive';
import {AddVaultToUserListCardComponent} from '../components/card/list-card/add-vault-to-user-list-card/add-vault-to-user-list-card.component';
import {ListBaseCardComponent} from '../components/card/list-card/list-base-card.component';
import {VaultUserGroupListComponent} from '../components/vault-user-group-list/vault-user-group-list.component';
import {UserGroupMemberListComponent} from '../components/user-group-member-list/user-group-member-list.component';
import {VaultMemberListComponent} from '../components/vault-member-list/vault-member-list.component';
import {AddUserToGroupListCardComponent} from '../components/card/list-card/add-user-to-group-list-card/add-user-to-group-list-card.component';
import {RemoveUserFromGroupListCardComponent} from '../components/card/list-card/remove-user-from-group-list-card/remove-user-from-group-list-card.component';
import {ResizableCardComponent} from '../components/card/resizable-card/resizable-card.component';
import {RemoveUsersFromVaultListCardComponent} from '../components/card/list-card/remove-users-from-vault-list-card/remove-users-from-vault-list-card.component';
import {SimpleInputDialogComponent} from '../components/dialogs/simple-input-dialog/simple-input-dialog.component';
import {SimpleMessageDialogComponent} from '../components/dialogs/simple-message-dialog/simple-message-dialog.component';
import {BaseSnackBarComponent} from '../components/dialogs/base-snack-bar/base-snack-bar.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {DocumentListItemComponent} from '../components/document-list-item/document-list-item.component';
import {DocumentListIconItemComponent} from '../components/document-list-item/document-list-icon-item/document-list-icon-item.component';
import {DocumentListIconButtonItemComponent} from '../components/document-list-item/document-list-icon-button-item/document-list-icon-button-item.component';
import {DocumentListIconCounterItemComponent} from '../components/document-list-item/document-list-icon-counter-item/document-list-icon-counter-item.component';
import {VaultOverviewComponent} from '../pages/layout-tablet/vault-overview/vault-overview.component';
import {TrashDocumentsComponent} from '../components/trash-documents/trash-documents.component';
import {SmartphonePreviewComponent} from '../pages/layout-smartphone/smartphone-preview/smartphone-preview.component';
import {BeginnersHelpComponent} from '../components/beginners-help/beginners-help.component';
import {SimpleDialogComponent} from '../components/dialogs/simple-dialog/simple-dialog.component';
import {KebabCasePipe} from '../pipes/kebab-case/kebab-case.pipe';
import {ServerSelectionComponent} from '../components/server-selection/server-selection.component';
import {StatusDialogComponent} from '../components/dialogs/status-dialog/status-dialog.component';
import {DocumentPreviewImageComponent} from '../components/dummy-components/document-preview-image.component';
import {DocumentAnnotationImageComponent} from '../components/dummy-components/document-annotation-image.component';
import {SubLineComponent} from '../components/dummy-components/sub-line.component';
import {BaseDocumentListComponent} from '../components/base-document-list/base-document-list.component';
import {TaskClassificationDocumentListComponent} from '../components/task-classification-document-list/task-classification-document-list.component';
import {StaticListComponent} from '../components/list-components/static-list/static-list.component';
import {FilteredListPrependItemDirective} from '../directives/filtered-list-prepend-item.directive';
import {FilteredListListItemDirective} from '../directives/filtered-list-list-item.directive';
import {AddDocumentTypeCategoryCardComponent} from '../components/card/add-document-type-category-card/add-document-type-category-card.component';
import {SolutionStoreSortingCardComponent} from '../components/card/solution-store-sorting-card/solution-store-sorting-card.component';
import {PermissionPipe} from '../pipes/permission/permission.pipe';
import {PermissionDirective} from '../directives/permission.directive';
import {AddDocumentTypeCardComponent} from '../components/card/list-card/add-document-type-card/add-document-type-card.component';
import {DarkModeEnabledPipe} from '../pipes/dark-mode-enabled/dark-mode-enabled.pipe';
import {ListModule} from './list/list.module';
import {IconComponent} from '../components/icon/icon.component';
import {PullToRefreshDirective} from '../directives/pull-to-refresh.directive';
import {BeginnersHelpRegisterDirective} from '../directives/beginners-help-register.directive';
import {DownloadCardComponent} from '../components/card/download-card/download-card.component';
import {StoredSearchesComponent} from '../components/stored-searches/stored-searches.component';

const matModules: Array<any> = [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    ScrollingModule,
    MatTooltipModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatSnackBarModule,
];

const modules: Array<any> = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild(),
    ListModule,
];

const cards: Array<any> = [
    CardComponent,
    SeeAlsoCardComponent,
    DownloadCardComponent,
    ShareCardComponent,
    NotImplementedCardComponent,
    ContentCardComponent,
    FindingsCardComponent,
    TagBaseCardComponent,
    DocumentTagsCardComponent,
    VaultTagsCardComponent,
    MagnetTagsCardComponent,
    ContactTagsCardComponent,
    ListViewCardComponent,
    AnnotationCardComponent,
    StampCardComponent,
];

const dialogs: Array<any> = [
    VaultSelectorDialogComponent,
    AddMagnetCardComponent,
    ImportDialogComponent,
    ImportFormDialogComponent,
    AddVaultCardComponent,
    SimpleInputDialogComponent,
    SimpleMessageDialogComponent,
    SimpleDialogComponent,
    BaseSnackBarComponent,
    StatusDialogComponent,
];

const directives: Array<any> = [
    TbFirstRowDirective,
    TbObjectIconLeftDirective,
    TbObjectIconRightDirective,
    NavigateToDirective,
    ListDragDownDirective,
    FilteredListPrependItemDirective,
    FilteredListListItemDirective,
    DragExportDirective,
    ImageInViewDirective,
    InViewDirective,
    PermissionDirective,
    ContentCardButtonsDirective,
    ClickDirective,
    OpenInBrowserDirective,
    BetterEventsDirective,
    AnnotationsLayerDirective,
    PermissionDirective,
];

const standaloneDirectives = [
    PullToRefreshDirective,
    DropImportDirective,
    BeginnersHelpRegisterDirective,
];

const components: Array<any> = [
    ToolbarComponent,
    ToolbarTabletComponent,
    FavoriteListComponent,
    DocumentsDashboardComponent,
    ContactsComponent,
    ToolbarTabsComponent,
    FastAccessMenuComponent,
    CustomIconComponent,
    DocumentListComponent,
    VaultAdministrationComponent,
    BaseDocumentPreviewComponent,
    BaseTaskDocumentPreviewComponent,
    BaseTaskComponent,
    MagnetsComponent,
    MagnetDocumentListComponent,
    InProgressListComponent,
    TasksDashboardComponent,
    FloatingActionButtonComponent,
    ActionItemGroupComponent,
    ActionItemComponent,
    SearchDashboardComponent,
    SearchResultVaultListComponent,
    SearchOverviewComponent,
    RangeSliderComponent,
    ContactDetailComponent,
    DocumentListStateIconsComponent,
    DocumentFilenameComponent,
    ToastContentComponent,
    FilteredListPaginationComponent,
    HistoryButtonComponent,
    HistoryOverlayComponent,
    DocumentViewComponent,
    DocumentViewDrawableComponent,
    DocumentViewTextItemsComponent,
    DocumentViewAnnotationsComponent,
    SearchNoResultContentComponent,
    TasksVaultListComponent,
    ActionMenuDocumentPreviewComponent,
    ListItemTextWithCounterComponent,
    VaultUserGroupListComponent,
    UserGroupMemberListComponent,
    VaultMemberListComponent,
    HistoryDashboardComponent,
    TaskMagnetTaskDocumentListComponent,
    ClickFinderListComponent,
    ClickFinderDocumentsComponent,
    ListBaseCardComponent,
    AddUserToGroupListCardComponent,
    RemoveUsersFromVaultListCardComponent,
    RemoveUserFromGroupListCardComponent,
    AddVaultToUserListCardComponent,
    ResizableCardComponent,
    DocumentListItemComponent,
    DocumentListIconItemComponent,
    DocumentListIconButtonItemComponent,
    DocumentListIconCounterItemComponent,
    VaultOverviewComponent,
    TrashDocumentsComponent,
    SmartphonePreviewComponent,
    ServerSelectionComponent,
    DocumentPreviewImageComponent,
    DocumentAnnotationImageComponent,
    SubLineComponent,
    BaseDocumentListComponent,
    TaskClassificationDocumentListComponent,
    StaticListComponent,
    AddDocumentTypeCategoryCardComponent,
    SolutionStoreSortingCardComponent,
    AddDocumentTypeCardComponent,
];

const standaloneComponents = [
    IconComponent,
    VaultsComponent,
    BeginnersHelpComponent,
    LoadingSpinnerComponent,
    StoredSearchesComponent,
];

const pipes = [
    AuthImagePipe,
    SanitizeUrlPipe,
    VaultIdToNamePipe,
    ExtAsyncPipe,
    DarkModeEnabledPipe,
];

const standalonePipes = [
    KebabCasePipe,
    PermissionPipe,
];

@NgModule({
    declarations: [
        ...components,
        ...cards,
        ...dialogs,
        ...pipes,
        ...directives,
    ],
    imports: [
        ...modules,
        ...matModules,
        ...standaloneDirectives,
        ...standaloneComponents,
        ...standalonePipes,
    ],
    exports: [
        ...modules,
        ...matModules,
        ...components,
        ...cards,
        ...dialogs,
        ...pipes,
        ...directives,
    ],
    providers: []
})
export class SharedModule {
    static injector: Injector;

    constructor(injector: Injector) {
        SharedModule.injector = injector;
    }
}
