import {ChangeDetectorRef, Component, ElementRef, Inject, Input, LOCALE_ID} from '@angular/core';
import {DocumentListItemComponent} from '../document-list-item.component';
import {DefaultIcon} from '../../../types/icons/default-icon.type';
import {Icon} from '../../../types/icons/icon.type';
import {ICON_PATH} from '../../../constants/image-paths.constants';
import {PermissionQuery} from '../../../queries/permission.query';
import {PermissionService} from '../../../services/permission/permission.service';

@Component({
    selector: 'app-document-list-icon-item',
    templateUrl: './document-list-icon-item.component.html',
    styleUrls: ['../document-list-item.component.scss', 'document-list-icon-item.component.scss']
})
export class DocumentListIconItemComponent extends DocumentListItemComponent {
    @Input() icon: string | null | undefined;
    @Input() svgIcon: Icon | undefined;
    @Input() iconClass: string | undefined;
    @Input() useCustomIcon: boolean;
    @Input() defaultIcon: DefaultIcon;
    protected readonly ICON_PATH = ICON_PATH;

    constructor(
        public hostReference: ElementRef,
        @Inject(LOCALE_ID)
        public localeId: string,
        public changeDetectorRef: ChangeDetectorRef,
        public permissionQuery: PermissionQuery,
        public permissionService: PermissionService,
    ) {
        super(hostReference, localeId, changeDetectorRef, permissionQuery, permissionService);
        this.defaultIcon = 'document';
        this.fetchAssignments = false;
        this.useCustomIcon = false;
    }
}
