import {ChangeDetectorRef, Component, ElementRef, Inject, Input, LOCALE_ID} from '@angular/core';
import {DocumentListIconItemComponent} from '../document-list-icon-item/document-list-icon-item.component';
import {ICON_PATH} from '../../../constants/image-paths.constants';
import {PermissionQuery} from '../../../queries/permission.query';
import {PermissionService} from '../../../services/permission/permission.service';

@Component({
    selector: 'app-document-list-icon-counter-item',
    templateUrl: './document-list-icon-counter-item.component.html',
    styleUrls: ['../document-list-item.component.scss', './document-list-icon-counter-item.component.scss']
})
export class DocumentListIconCounterItemComponent extends DocumentListIconItemComponent {
    @Input() count: number | undefined;
    protected readonly ICON_PATH = ICON_PATH;

    constructor(
        public hostReference: ElementRef,
        @Inject(LOCALE_ID)
        public localeId: string,
        public changeDetectorRef: ChangeDetectorRef,
        public permissionQuery: PermissionQuery,
        public permissionService: PermissionService,
    ) {
        super(hostReference, localeId, changeDetectorRef, permissionQuery, permissionService);
    }
}
