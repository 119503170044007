import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ContentChildren,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    Type,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import {ActionItemGroupComponent} from '../action-item-group/action-item-group.component';
import {ActionItemComponent} from '../action-item/action-item.component';
import {ActionType} from '../../types/action-menu.type';
import {AppQuery} from '../../queries/app.query';
import {Observable} from 'rxjs/internal/Observable';
import {AppService} from '../../services/app/app.service';
import {DocumentService} from '../../services/document/document.service';
import {combineLatest, firstValueFrom, of, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap, take} from 'rxjs/operators';
import {TextItem} from 'src/app/api/models/text-item';
import {VaultQuery} from '../../queries/vault.query';
import {LOCAL_FILE_SERVICE, LocalFileService} from '../../services/local-file/local-file.service';
import {SearchQuery} from '../../queries/search.query';
import {Document} from '../../api/models/document';
import {DocumentQuery} from '../../queries/document.query';
import {HapticsService} from '../../services/haptics/haptics.service';
import {actionCardLocationEnum, ActionCardLocationType} from '../../types/action-card-location-enum';
import {ImportService} from '../../services/import/import.service';
import {DocumentViewMode} from '../../models/document-view-mode';
import {AnnotationService} from '../../services/annotation/annotation.service';
import {SelectedItemDescription, SelectedItemDescriptionType} from '../../models/selected-item-description-type';
import {AnnotationQuery} from '../../queries/annotation.query';
import {DialogService} from '../../services/dialog/dialog.service';
import {defaultContextmenuDelay} from '../../constants/ui/default-contextmenu-delay.constant';
import {ActivatedRoute} from '@angular/router';
import {MatTooltip} from '@angular/material/tooltip';
import {Preferences} from '../../api/models/preferences';
import {ListService} from '../../services/list/list.service';
import {CheckedOutDocumentService} from '../../services/checked-out-document/checked-out-document.service';
import {IconsComponent} from '../dummy-components/icons.component';
import {ACTION_TYPES} from '../../constants/action-type.constants';
import {DocumentsInitGlobalSearchService} from '../../services/search/documents-init-global-search/documents-init-global-search.service';
import {SearchResultViewService} from '../../services/search/search-result-view/search-result-view.service';
import {RecentSearchService} from '../../services/search/recent-search/recent-search.service';
import {SeeAlsoCardComponent} from '../card/see-also-card/see-also-card.component';
import {AddVaultCardComponent} from '../card/add-vault-card/add-vault-card.component';
import {AddDocumentTypeCardComponent} from '../card/list-card/add-document-type-card/add-document-type-card.component';
import {AddDocumentTypeCategoryCardComponent} from '../card/add-document-type-category-card/add-document-type-category-card.component';
import {AddVaultToUserListCardComponent} from '../card/list-card/add-vault-to-user-list-card/add-vault-to-user-list-card.component';
import {RemoveUserFromGroupListCardComponent} from '../card/list-card/remove-user-from-group-list-card/remove-user-from-group-list-card.component';
import {RemoveUsersFromVaultListCardComponent} from '../card/list-card/remove-users-from-vault-list-card/remove-users-from-vault-list-card.component';
import {AddUserToGroupListCardComponent} from '../card/list-card/add-user-to-group-list-card/add-user-to-group-list-card.component';
import {StampCardComponent} from '../card/stamp-card/stamp-card.component';
import {AnnotationCardComponent} from '../card/annotation-card/annotation-card.component';
import {SolutionStoreSortingCardComponent} from '../card/solution-store-sorting-card/solution-store-sorting-card.component';
import {ListViewCardComponent} from '../card/list-view-card/list-view-card.component';
import {FindingsCardComponent} from '../card/findings-card/findings-card.component';
import {NotImplementedCardComponent} from '../card/not-implemented-card/not-implemented-card.component';
import {ContactTagsCardComponent} from '../card/tag-card/contact-tags-card/contact-tags-card.component';
import {MagnetTagsCardComponent} from '../card/tag-card/magnet-tags-card/magnet-tags-card.component';
import {VaultTagsCardComponent} from '../card/tag-card/vault-tags-card/vault-tags-card.component';
import {DocumentTagsCardComponent} from '../card/tag-card/document-tags-card/document-tags-card.component';
import {ShareCardComponent} from '../card/share-card/share-card.component';
import {DownloadCardComponent} from '../card/download-card/download-card.component';
import {AddMagnetCardComponent} from '../card/add-magnet-card/add-magnet-card.component';
import {BrowserQuery} from '../../queries/browser.query';
import {PLATFORMS} from '../../constants/device';
import {isTouchDevice} from '../../util/is-touch-device';
import {elementIsVisible} from '../../util/element-is-visible';

@Component({
    selector: 'app-floating-action-button',
    templateUrl: './floating-action-button.component.html',
    styleUrls: ['./floating-action-button.component.scss']
})
export class FloatingActionButtonComponent extends IconsComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
    @Input() location: ActionCardLocationType;
    @Input() data: Record<string, any> | undefined;
    @Input() isTaskSearchMenu: boolean;
    @Input() hideWhenEmpty: boolean;
    @HostBinding('class.hide') hideClass: boolean;
    @ContentChildren(ActionItemGroupComponent, { descendants: true }) contentActionGroups: QueryList<ActionItemGroupComponent> | null;
    @ContentChildren(ActionItemComponent, { descendants: true }) contentActionItems: QueryList<ActionItemComponent> | null;
    @ViewChild('cardContainer', { read: ViewContainerRef }) cardContainer: ViewContainerRef | undefined;
    @ViewChildren('beginnersHelpTooltip') tooltips: QueryList<MatTooltip>;

    protected isLargeMenuOpen: boolean;
    protected sortedActionItems: Array<ActionItemComponent> | undefined;
    protected currentActionMenu$: Observable<ActionType | undefined>;
    protected textItems$: Observable<Array<TextItem>>;
    protected isShowingSmallMenu$: Observable<boolean>;
    protected documentViewMode$: Observable<DocumentViewMode>;
    protected countTemporaryAnnotations$: Observable<number>;
    protected selectedAnnotation$: Observable<SelectedItemDescription | undefined>;
    protected isSelectedAnnotationTemporary$: Observable<boolean>;
    protected canShowNextButton$: Observable<boolean>;
    protected cardId: string;
    protected isAddingStamp$: Observable<boolean>;
    protected preferences$: Observable<Preferences>;
    private subscription: Subscription;
    private selectedDocument$: Observable<Document | undefined>;
    private selectedAnnotationType$: Observable<SelectedItemDescriptionType | undefined>;
    private menuTimer: any;
    private readonly isIOSPlatform: boolean;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private appQuery: AppQuery,
        private appService: AppService,
        private documentService: DocumentService,
        private vaultQuery: VaultQuery,
        private searchQuery: SearchQuery,
        private documentQuery: DocumentQuery,
        private activatedRoute: ActivatedRoute,
        @Inject(LOCAL_FILE_SERVICE)
        private localFileService: LocalFileService,
        private hapticsService: HapticsService,
        private importService: ImportService,
        private annotationService: AnnotationService,
        private annotationQuery: AnnotationQuery,
        private dialogService: DialogService,
        private listService: ListService,
        private checkedOutDocumentService: CheckedOutDocumentService,
        private documentsInitGlobalSearchService: DocumentsInitGlobalSearchService,
        private searchResultViewService: SearchResultViewService,
        private recentSearchService: RecentSearchService,
        private browserQuery: BrowserQuery,
        private element: ElementRef,
    ) {
        super();
        this.tooltips = new QueryList<MatTooltip>();
        this.isTaskSearchMenu = false;
        this.location = actionCardLocationEnum.sidebar;
        this.subscription = new Subscription();
        this.isLargeMenuOpen = false;
        this.contentActionGroups = null;
        this.contentActionItems = null;
        this.hideClass = false;
        this.hideWhenEmpty = true;
        this.isShowingSmallMenu$ =
            combineLatest([this.appQuery.isShowingShortMenuSidebar$, this.appQuery.isShowingShortMenuContent$])
                .pipe(map(([menuSidebar, menuContent]: [boolean, boolean]) => {
                    if (this.location === actionCardLocationEnum.sidebar) {
                        return menuSidebar;
                    } else {
                        return menuContent;
                    }
                }));
        this.currentActionMenu$ =
            combineLatest([this.appQuery.currentActionMenuSidebar$, this.appQuery.currentActionMenuContent$])
                .pipe(map(([menuSidebar, menuContent]: [ActionType | undefined, ActionType | undefined]) => {
                    if (this.location === actionCardLocationEnum.sidebar) {
                        return menuSidebar;
                    } else {
                        return menuContent;
                    }
                }));
        this.selectedAnnotation$ = this.documentQuery.selectedAnnotation$;
        this.isSelectedAnnotationTemporary$ = this.selectedAnnotation$.pipe(map(a => {
            if (a && a.id) {
                return a.id.includes('TEMP_');
            }
            return false;
        }));
        this.selectedAnnotationType$ = this.selectedAnnotation$.pipe(filter(a => a !== undefined), map(a => a?.type));
        this.textItems$ = this.searchQuery.listSelectedTextItems$;
        this.appService.setShowingSmallMenuSidebar(false);
        this.selectedDocument$ = this.documentQuery.selectedDocument$;
        this.documentViewMode$ = this.documentQuery.documentViewMode$;
        this.countTemporaryAnnotations$ = this.documentQuery.temporaryAnnotations$.pipe(map(temporaryAnnotations => {
            let num = 0;
            if (temporaryAnnotations) {
                const annotations = temporaryAnnotations as { [key: string]: any };
                Object.keys(annotations)
                    .forEach(key => {
                        num += annotations[key].length;
                    });
            }
            return num;
        }));
        this.isAddingStamp$ = this.annotationQuery.currentStampId$.pipe(map(currentStampId => currentStampId !== undefined));

        const isTasksPage$ = this.activatedRoute.paramMap.pipe(map(params =>
            params.has('taskId') && !!params.get('taskId')));

        const hasStampableActiveDocumentsInList$: Observable<boolean> = this.listService.latestList$.pipe(switchMap(latestList => {
            if (latestList) {
                return latestList?.dataList$.pipe(map(documents => documents.filter(document => document?.state === 'Ready' && !document.checkedOut)), map(documents => documents.length > 1));
            }
            return of(false);
        }));

        this.canShowNextButton$ =
            combineLatest([isTasksPage$, this.documentQuery.hasStampableActiveDocument$, hasStampableActiveDocumentsInList$, this.annotationQuery.currentStampId$])
                .pipe(map(([isTaskPage, hasStampableActiveDocument, hasStampableActiveDocumentsInList, currentStampId]: [boolean, boolean, boolean, string | undefined]) => isTaskPage &&
                    (hasStampableActiveDocument || hasStampableActiveDocumentsInList) && !!currentStampId));
        this.cardId = '';
        this.preferences$ = this.appQuery.preferences$;
        this.isIOSPlatform = this.browserQuery.isNativePlatform() ? (this.browserQuery.getPlatform() === PLATFORMS.IOS) : this.browserQuery.isIosWeb();
    }

    public ngOnInit(): void {
        this.subscription.add(this.isShowingSmallMenu$.subscribe(isShowingShortMenu => {
            if (isShowingShortMenu) {
                this.isLargeMenuOpen = false;
            }
        }));
    }

    public ngAfterContentInit(): void {
        this.contentActionItems?.changes.subscribe(() => this.updateContentElements());
        this.updateContentElements();
        this.cardId =
            'ACTION_MENU' + (window.location.pathname.replace(/\/(\w{8}\-?)(\w{4}\-?){3}(\w{12}\-?)/gm, '')
                .replace(/\//gm, '_') + '_' + this.location)
                .toUpperCase();

    }

    public ngAfterViewInit(): void {
        if (isTouchDevice()) {
            this.subscription.add(this.tooltips.changes.subscribe(tooltips => {
                tooltips.forEach((tooltip: MatTooltip) => {
                    if (elementIsVisible(this.element.nativeElement)) {
                        tooltip.hide();
                        tooltip.show();
                    }
                });
                this.changeDetectorRef.detectChanges();
            }));
        }

        this.subscription.add(((this.location === actionCardLocationEnum.sidebar) ? this.appQuery.currentActionMenuSidebar$ : this.appQuery.currentActionMenuContent$).pipe(distinctUntilChanged())
            .subscribe(async (menu) => {
                await this.setMenu(menu);
            }));
        this.appService.setHasActionButton(true);
    }

    public ngOnDestroy(): void {
        this.appService.removeAllCurrentActionMenus();
        this.subscription.unsubscribe();
        this.appService.setHasActionButton(false);
    }

    public async closeMenu(): Promise<void> {
        const documentViewMode: DocumentViewMode = await firstValueFrom(this.documentViewMode$);
        if (documentViewMode !== 'Annotations' && documentViewMode !== 'Drawing') {
            if (this.location === actionCardLocationEnum.sidebar) {
                this.appService.setShowingSmallMenuSidebar(false);
            } else {
                this.appService.setShowingSmallMenuContent(false);
            }
            this.isLargeMenuOpen = false;
        } else {
            this.closeAction();
        }
    }

    public async onMouseUp(): Promise<void> {
        if (!this.isLargeMenuOpen) {
            clearTimeout(this.menuTimer);
            await this.openSmallMenu();
        }
    }

    public onMouseDown(): void {
        this.menuTimer = setTimeout(async () => {
            await this.openLargeMenu();
        }, defaultContextmenuDelay);
    }

    public async openSmallMenu(): Promise<void> {
        await this.hapticsService.hapticsMedium();
        if (this.location === actionCardLocationEnum.sidebar) {
            this.appService.setShowingSmallMenuContent(false);
            this.appService.setShowingSmallMenuSidebar(true);
        } else {
            this.appService.setShowingSmallMenuSidebar(false);
            this.appService.setShowingSmallMenuContent(true);
        }
        this.isLargeMenuOpen = false;
    }

    public async openLargeMenu(): Promise<void> {
        await this.hapticsService.hapticsHeavy();
        this.isLargeMenuOpen = true;
    }

    public updateContentElements(): void {
        setTimeout(() => {
            this.sortedActionItems = this.contentActionItems?.toArray()
                .map(item => {
                    item.location = this.location;
                    return item;
                })
                .sort((a, b) => {
                    return a.smallMenuOrderNumber > b.smallMenuOrderNumber ? 1 : -1;
                });
            this.hideClass = (this.hideWhenEmpty && this.sortedActionItems?.length === 0);
        });
    }

    public openActionMenu(action: ActionType): void {
        if (this.location === actionCardLocationEnum.sidebar) {
            this.appService.setCurrentActionMenuSidebar(action);
            this.appService.setShowingSmallMenuSidebar(false);
        } else {
            this.appService.setCurrentActionMenuContent(action);
            this.appService.setShowingSmallMenuContent(false);
        }
    }

    public itemClickedEmitter(item: ActionItemComponent): void {
        item.clicked.emit();
    }

    public async searchForTextGlobal(e: Event): Promise<void> {
        e.preventDefault();
        e.stopPropagation();

        const searchWord = await this.getSearchWord();
        if (searchWord.length === 0) {
            return;
        }

        await this.searchText(searchWord, true);
    }

    public async searchForTextLocal(e: Event): Promise<void> {
        e.preventDefault();
        e.stopPropagation();

        this.appService.setIsNotShowingSearchOnGoingBack(true);

        const searchWord = await this.getSearchWord();
        if (searchWord.length === 0) {
            return;
        }

        const currentVault = this.vaultQuery.getActiveId();
        if (!currentVault) {
            return;
        }

        await this.searchText(searchWord, false, [currentVault as string]);
    }

    public async shareSearchText(e: MouseEvent): Promise<void> {
        e.preventDefault();
        e.stopPropagation();

        this.appService.setIsNotShowingSearchOnGoingBack(true);
        const searchWord = await this.getSearchWord();
        if (searchWord.length === 0) {
            return;
        }
        await this.localFileService.shareUrl(searchWord);
    }

    public saveAction(e: Event): void {
        e.preventDefault();
        e.stopPropagation();
        this.annotationService.actionMenuOnSave.emit();
    }

    public deleteAction(e: MouseEvent): void {
        e.preventDefault();
        e.stopPropagation();
        if (this.annotationService.actionMenuOnDelete !== undefined) {
            this.annotationService.actionMenuOnDelete();
        }
    }

    public showAnnotationMenuAction(e: Event): void {
        e.preventDefault();
        e.stopPropagation();
        if (this.annotationService.actionMenuOnShowAnnotationMenu !== undefined) {
            this.annotationService.actionMenuOnShowAnnotationMenu();
        }
    }

    public copyAction(e: Event): void {
        e.preventDefault();
        e.stopPropagation();
        if (this.annotationService.actionMenuOnCopy !== undefined) {
            this.annotationService.actionMenuOnCopy();
        }
    }

    public nextAction(e: Event): void {
        e.preventDefault();
        e.stopPropagation();
        this.annotationService.actionMenuOnNext.next();
    }

    public async nextStampAction(e: Event, saveStamp: boolean): Promise<void> {
        e.preventDefault();
        e.stopPropagation();
        if (saveStamp) {
            this.saveAction(e);
            const sub = this.annotationService.actionMenuOnSaveDone.subscribe(() => {
                this.annotationService.actionMenuOnNextStampDocument.next();
                sub.unsubscribe();
            });
        } else {
            const cancelStamping = await this.dialogService.showCancelStampingDialog();
            if (cancelStamping) {
                this.annotationService.actionMenuOnNextStampDocument.next();
            }
        }
    }

    public closeAction(): void {
        this.annotationService.actionMenuOnClose.emit();
    }

    public async closeMenuFromMenu(): Promise<void> {
        const documentViewMode = await firstValueFrom(this.documentViewMode$);
        if (documentViewMode === 'Viewing') {
            await this.closeMenu();
        }
    }

    private async searchText(searchWord: string, isGlobalView: boolean, vaultIdArray?: Array<string>) {
        const searchResult = await this.documentsInitGlobalSearchService.fetch(searchWord, vaultIdArray);

        if (searchResult) {
            await this.searchResultViewService.openResultView(searchResult, false, isGlobalView);
            await this.recentSearchService.add(searchResult);
        } else {
            await this.searchResultViewService.openNoResultView(false, false);
        }
    }

    private async getSearchWord(): Promise<string> {
        return await firstValueFrom(this.textItems$.pipe(
            take(1),
            map(items =>
                items.map(item => item.text)
                    .join(' ')
            )));
    }

    private addComponentInstanceData<C>(componentType: Type<C>): ComponentRef<C> | undefined {
        if (!this.cardContainer) {
            return;
        }
        const componentRef: ComponentRef<C> = this.cardContainer.createComponent(componentType);
        if (componentRef) {
            // @ts-ignore
            componentRef.instance.location = this.location;
            // @ts-ignore
            componentRef.instance.data = this.data;

            // We need a double-click event on stamp-cards. Set `touch-action: auto` on
            // iOS devices, because `touch-action: none` (default) prevents double-click
            // events on elements inside the container on iOS devices.
            if (this.isIOSPlatform) {
                // @ts-ignore
                componentRef.instance.touchAction = 'auto';
            }
            return componentRef;
        }
        return;
    }

    private async setMenu(menu: ActionType | undefined) {
        if (!this.cardContainer) {
            return;
        }
        switch (menu) {
            case ACTION_TYPES.SEE_ALSO:
                this.addComponentInstanceData(SeeAlsoCardComponent);
                break;
            case ACTION_TYPES.ADD_VAULT:
                this.addComponentInstanceData(AddVaultCardComponent);
                break;
            case ACTION_TYPES.ADD_MAGNET:
                this.addComponentInstanceData(AddMagnetCardComponent);
                break;
            case ACTION_TYPES.DOWNLOAD:
                this.addComponentInstanceData(DownloadCardComponent);
                break;
            case ACTION_TYPES.SHARE:
                this.addComponentInstanceData(ShareCardComponent);
                break;
            case ACTION_TYPES.DOCUMENT_TAGS:
                this.addComponentInstanceData(DocumentTagsCardComponent);
                break;
            case ACTION_TYPES.VAULT_TAGS:
                this.addComponentInstanceData(VaultTagsCardComponent);
                break;
            case ACTION_TYPES.MAGNET_TAGS:
                this.addComponentInstanceData(MagnetTagsCardComponent);
                break;
            case ACTION_TYPES.CONTACT_TAGS:
                this.addComponentInstanceData(ContactTagsCardComponent);
                break;
            case ACTION_TYPES.NOT_IMPLEMENTED:
                this.addComponentInstanceData(NotImplementedCardComponent);
                break;
            case ACTION_TYPES.FINDINGS:
                this.addComponentInstanceData(FindingsCardComponent);
                break;
            case ACTION_TYPES.LIST_VIEW:
                this.addComponentInstanceData(ListViewCardComponent);
                break;
            case ACTION_TYPES.SOLUTION_STORE_SORTING:
                this.addComponentInstanceData(SolutionStoreSortingCardComponent);
                break;
            case ACTION_TYPES.IMPORT:
                this.importService.showImportDialog(undefined, undefined, undefined, this.data?.importTarget, this.data?.collisionSetting);
                this.importService.checkForCheckingInDocument()
                    .then(importDocumentId => {
                        if (importDocumentId) {
                            this.checkedOutDocumentService.checkInDocument(importDocumentId)
                                .then();
                        }
                    });
                this.appService.removeAllCurrentActionMenus();
                break;
            case ACTION_TYPES.ANNOTATION:
                this.addComponentInstanceData(AnnotationCardComponent);
                break;
            case ACTION_TYPES.STAMPS:
                this.addComponentInstanceData(StampCardComponent);
                break;
            case ACTION_TYPES.ADD_USERS_TO_GROUP:
                this.addComponentInstanceData(AddUserToGroupListCardComponent);
                break;
            case ACTION_TYPES.REMOVE_USERS_FROM_VAULT:
                this.addComponentInstanceData(RemoveUsersFromVaultListCardComponent);
                break;
            case ACTION_TYPES.REMOVE_USERS_FROM_GROUP:
                this.addComponentInstanceData(RemoveUserFromGroupListCardComponent);
                break;
            case ACTION_TYPES.ADD_VAULT_TO_USER:
                this.addComponentInstanceData(AddVaultToUserListCardComponent);
                break;
            case ACTION_TYPES.ADD_DOCUMENT_TYPE_CATEGORY:
                this.addComponentInstanceData(AddDocumentTypeCategoryCardComponent);
                break;
            case ACTION_TYPES.ADD_DOCUMENT_TYPE:
                this.addComponentInstanceData(AddDocumentTypeCardComponent);
                break;
            default:
                this.cardContainer?.clear();
                await this.documentService.clearDocumentAssignments();
                break;
        }
    }
}
