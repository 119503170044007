import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {PermissionState, PermissionStore} from '../stores/permission.store';
import {VaultPermission} from '../types/permissions/vault-permission';
import {MePermission} from '../types/permissions/me-permission';
import {combineLatest, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {UserGroupPermission} from '../types/permissions/user-group-permission';
import {DocumentPermission} from '../types/permissions/document-permission';
import {CheckedOutDocumentPermission} from '../types/permissions/checked-out-document-permission';
import {MagnetPermission} from '../types/permissions/magnet-permission';
import {TrashedDocumentsPermission} from '../types/permissions/trashed-documents-permission';
import {DocumentTypeCategoryPermission} from '../types/permissions/document-type-category-permission';

@Injectable({ providedIn: 'root' })
export class PermissionQuery extends Query<PermissionState> {
    public mePermissions$: Observable<Array<MePermission>> = this.select('mePermissions');
    public vaultPermissions$: Observable<Record<string, Array<VaultPermission>>> = this.select('vaultPermissions');
    public documentPermissions$: Observable<Record<string, Array<DocumentPermission>>> = this.select('documentPermissions');
    public trashedDocumentPermissions$: Observable<Record<string, Array<TrashedDocumentsPermission>>> = this.select('trashedDocumentPermissions');
    public permissionsUpdate$: Observable<unknown>;
    private magnetPermissions$: Observable<Record<string, Array<MagnetPermission>>> = this.select('magnetPermissions');
    private userGroupPermissions$: Observable<Record<string, Array<UserGroupPermission>>> = this.select('userGroupPermissions');
    private checkedOutDocumentPermissions$: Observable<Record<string, Array<CheckedOutDocumentPermission>>> = this.select('checkedOutDocumentPermissions');

    constructor(
        protected store: PermissionStore,
    ) {
        super(store);
        this.permissionsUpdate$ =
            combineLatest([this.vaultPermissions$, this.mePermissions$, this.userGroupPermissions$, this.documentPermissions$, this.checkedOutDocumentPermissions$, this.magnetPermissions$])
                .pipe(debounceTime(0), distinctUntilChanged());
    }

    public hasVaultPermission(vaultId: string, permission: VaultPermission): boolean {
        const vaultPermissions = this.getValue().vaultPermissions;
        return vaultId in vaultPermissions && vaultPermissions[vaultId].includes(permission);
    }

    public hasMePermission(permission: MePermission): boolean {
        const mePermissions = this.getValue().mePermissions;
        return mePermissions.includes(permission);
    }

    public hasUserGroupPermission(userGroupId: string, permission: UserGroupPermission): boolean {
        const userGroupPermissions = this.getValue().userGroupPermissions;
        return userGroupId in userGroupPermissions && userGroupPermissions[userGroupId].includes(permission);
    }

    public hasDocumentPermission(documentId: string, permission: DocumentPermission): boolean {
        const documentPermissions = this.getValue().documentPermissions;
        return documentId in documentPermissions && documentPermissions[documentId].includes(permission);
    }

    public hasCheckedOutDocumentPermission(checkedOutDocumentId: string, permission: CheckedOutDocumentPermission): boolean {
        const documentPermissions = this.getValue().checkedOutDocumentPermissions;
        return checkedOutDocumentId in documentPermissions && documentPermissions[checkedOutDocumentId].includes(permission);
    }

    public hasDocumentPermissionLoaded(documentId: string): boolean {
        return documentId in this.getValue().documentPermissions;
    }

    public hasCheckedOutDocumentPermissionLoaded(documentId: string): boolean {
        return documentId in this.getValue().checkedOutDocumentPermissions;
    }

    public hasMagnetPermission(magnetId: string, permission: MagnetPermission): boolean {
        const magnetPermissions = this.getValue().magnetPermissions;
        return magnetId in magnetPermissions && magnetPermissions[magnetId].includes(permission);
    }

    public hasTrashedDocumentPermission(documentId: string, trashedDocumentsPermission: TrashedDocumentsPermission): boolean {
        const trashedDocumentPermissions = this.getValue().trashedDocumentPermissions;
        return documentId in trashedDocumentPermissions && trashedDocumentPermissions[documentId].includes(trashedDocumentsPermission);
    }

    public hasTrashedDocumentPermissionLoaded(documentId: string): boolean {
        return documentId in this.getValue().trashedDocumentPermissions;
    }

    public hasDocumentTypeCategoryPermissionLoaded(documentTypeCategoryId: string): boolean {
        return documentTypeCategoryId in this.getValue().documentTypeCategoryPermissions;
    }

    public hasDocumentTypeCategoryPermission(documentTypeCategoryId: string, documentTypeCategoryPermission: DocumentTypeCategoryPermission): boolean {
        const documentTypeCategoryPermissions = this.getValue().documentTypeCategoryPermissions;
        return documentTypeCategoryPermissions[documentTypeCategoryId] && documentTypeCategoryPermissions[documentTypeCategoryId].includes(documentTypeCategoryPermission);
    }
}
