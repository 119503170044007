import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {AkitaNgRouterStoreModule} from '@datorama/akita-ng-router-store';
import {SharedModule} from './modules/shared.module';
import {ApiModule} from './api/api.module';
import {ApiModule as BfaApiModule} from './bfa-api/api.module';
import '@angular/common/locales/global/de';
import {Router} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpBackend} from '@angular/common/http';
import {LanguageService} from './services/language/language.service';
import {LOCAL_FILE_SERVICE} from './services/local-file/local-file.service';
import {WebLocalFileService} from './services/local-file/web/web-local-file.service';
import {appInitializerFactory, httpLoaderFactory, platformDependentClass} from './util/ng-module-utils';
import {AuthInterceptor} from './http-interceptors/auth-interceptor';
import {TaskService} from './services/task/task.service';
import {CheckedOutDocumentService} from './services/checked-out-document/checked-out-document.service';
import {MobileLocalFileService} from './services/local-file/mobile/mobile-local-file.service';
import {DebugMenuComponent} from './components/debug-menu/debug-menu.component';
import {TutorialComponent} from './components/tutorial/tutorial.component';
import {AndroidLocalFileService} from './services/local-file/android/android-local-file.service';
import {getBrowserLanguage} from './util/get-browser-language';
import {BrowserQuery} from './queries/browser.query';
import {createErrorHandler, TraceService} from '@sentry/angular';
import {environment} from '../environments/environment';
import {FileOpener} from '@capacitor-community/file-opener';
import {AppService} from './services/app/app.service';
import {CacheBustingInterceptor} from './http-interceptors/cache-busting-interceptor';
import {STATIC_CONFIGS} from '../configs/static.config';
import {ServerAddress} from './models/server-address';
import {ElectronLocalFileService} from './services/local-file/electron/electron-local-file.service';
import {ApiInterceptor} from './http-interceptors/api.interceptor';
import {API_URL_PLACEHOLDER} from './constants/api-url-placeholder.constants';
import {ServiceWorkerModule} from '@angular/service-worker';
import {ConnectionInterceptor} from './http-interceptors/connection.interceptor';

// Remove internal-API path segment from server URLs, due to downward compatibility
// @see https://amagno.atlassian.net/browse/APP-1024
const reduxStore = JSON.parse(localStorage.getItem(STATIC_CONFIGS.localStorageName) ?? '{}');

if (reduxStore && reduxStore.app && reduxStore.app.servers) {
    reduxStore.app.servers = reduxStore.app.servers.map((serverAddress: ServerAddress) => {
        serverAddress.url = serverAddress.url.replace(new RegExp('/' + STATIC_CONFIGS.apis.internal.path + '$'), '');

        return serverAddress;
    });

    localStorage.setItem(STATIC_CONFIGS.localStorageName, JSON.stringify(reduxStore));
}

const defaultProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ConnectionInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheBustingInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
    {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFactory,
        deps: [TranslateService, Router, LanguageService, BrowserQuery, AppService],
        multi: true
    },
    {
        provide: LOCALE_ID,
        deps: [LanguageService],
        useFactory: (languageService: LanguageService) => {
            return languageService.getLanguage();
        }
    }, {
        provide: LOCAL_FILE_SERVICE,
        useClass: platformDependentClass({
            web: WebLocalFileService,
            android: AndroidLocalFileService,
            ios: MobileLocalFileService,
            electron: ElectronLocalFileService,
        }),
    },
    { provide: 'Window', useValue: window },
    FileOpener
];

const sentryProviders = [
    {
        provide: ErrorHandler,
        useValue: createErrorHandler({
            showDialog: false, // @TODO: Show dialog for users to give feedback
        })
    },
    {
        provide: TraceService,
        deps: [Router]
    },
];

@NgModule({
    declarations: [
        AppComponent,
        TutorialComponent,
    ],
    imports: [
        DebugMenuComponent,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        AkitaNgDevtools.forRoot(),
        AkitaNgRouterStoreModule,
        BfaApiModule.forRoot({ rootUrl: environment.customBfaUrl ?? API_URL_PLACEHOLDER + '/' + STATIC_CONFIGS.apis.bfa.path }),
        ApiModule.forRoot({ rootUrl: API_URL_PLACEHOLDER + '/' + STATIC_CONFIGS.apis.internal.path }),
        ServiceWorkerModule.register('ngsw-worker.js'),
        TranslateModule.forRoot({
            defaultLanguage: getBrowserLanguage(),
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpBackend]
            }
        }),
    ],
    // @ts-ignore
    providers: (environment.sentry) ? defaultProviders.concat(sentryProviders) : defaultProviders,
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        taskService: TaskService, // we need to have taskService instantiated after startup as it is responsible for the open task polling
        checkoutDocumentService: CheckedOutDocumentService, // needed to have the initial file sync work on devices
        trace: TraceService
    ) {
    }
}
