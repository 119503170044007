import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {getLanguageFromCulture} from '../../util/get-language-from-culture';
import {AuthQuery} from '../../queries/auth.query';
import {getBrowserLocale} from '../../util/get-browser-locale';
import {defaultLocale} from '../../constants/locale/default-locale.constant';
import {locales} from '../../constants/locale/locales.constant';
import {DOCUMENT} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private document: Document;

    constructor(
        private translateService: TranslateService,
        private authQuery: AuthQuery,
        @Inject(DOCUMENT) document: Document,
    ) {
        this.document = document;
    }

    public getCultureName(): string {
        if (this.authQuery.getIsLoggedIn()) {
            const cultureName = this.authQuery.getLanguageCultureName();
            if (locales.includes(cultureName)) {
                return cultureName;
            }
            return defaultLocale;
        }
        return getBrowserLocale();
    }

    public getLanguage(): string {
        const cultureName = this.getCultureName();
        return getLanguageFromCulture(cultureName);
    }

    public async setCurrentLanguage(): Promise<string> {
        const cultureName = this.getCultureName();
        const lang = getLanguageFromCulture(cultureName);

        // eslint-disable-next-line no-console
        console.info(`Set locale to '${cultureName}'.`);

        this.translateService.use(lang);
        this.setDocumentLanguage(lang);

        return cultureName;
    }

    private setDocumentLanguage(lang: string): void {
        this.document.documentElement.setAttribute('lang', lang);
    }
}
