import {Injectable} from '@angular/core';
import {AppQuery} from '../../queries/app.query';
import {AppService} from '../app/app.service';
import {ServerAddress} from '../../models/server-address';
import {ServerInformation} from '../../bfa-api/models/server-information';
import {NotificationStore} from '../../stores/notification.store';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private appQuery: AppQuery,
        private appService: AppService,
        private notificationStore: NotificationStore,
    ) {
    }

    public setHasReadNotificationInSession(hasReadNotificationInSession: boolean): void {
        this.notificationStore.update({ hasReadNotificationInSession });
    }

    public updateNotificationIfNecessary(serverInformation: ServerInformation): void {
        const selectedServer = this.appQuery.getSelectedServer();
        if (!selectedServer || selectedServer?.currentNotificationId === serverInformation.notification?.notificationId) {
            return;
        } else {
            const updatedSelectedServer: ServerAddress = {
                ...selectedServer,
                serverInformation: serverInformation,
                currentNotificationId: serverInformation.notification?.notificationId ?? undefined,
                notificationReadByUserIds: [],
            };

            this.appService.updateServers(this.appQuery.getServers()
                .map((serverAddress) => serverAddress.selected ? updatedSelectedServer : serverAddress));
        }
    }


}
