import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, Input, LOCALE_ID} from '@angular/core';
import {ListDisplayEnum} from '../../enums/list-display.enum';
import {BehaviorSubject} from 'rxjs';
import {IconsComponent} from '../dummy-components/icons.component';
import {ICON_PATH} from '../../constants/image-paths.constants';
import {PermissionQuery} from '../../queries/permission.query';
import {PermissionService} from '../../services/permission/permission.service';

@Component({
    selector: 'app-document-list-item',
    templateUrl: './document-list-item.component.html',
    styleUrls: ['./document-list-item.component.scss']
})
export class DocumentListItemComponent extends IconsComponent implements AfterViewInit {

    @HostBinding('class') classNames: string;
    @Input() fetchAssignments: boolean;
    @Input() ignoreMinWidth: boolean | any;
    @Input() useDocumentAttributes: boolean;
    @Input() subLine: string | undefined;
    @Input() useSubLine: boolean;
    @Input() plainName: boolean;
    @Input() line: string | undefined;
    @Input() deactivateListImageCache: boolean;

    protected itemData: any;
    protected tagName: string;
    protected previewImageVersionString: string;
    protected annotationImageVersionString: string;
    protected listDisplayType$: BehaviorSubject<ListDisplayEnum>;
    protected listDisplayTypes = ListDisplayEnum;
    protected hostElement: HTMLElement | undefined;
    protected onImageLoaded: () => void;
    protected onImageLoadingError: () => void;
    protected isImageLoaded: boolean;
    protected onAnnotationImageLoaded: () => void;
    protected showingThumbnail: boolean;
    protected isMarked: boolean;
    protected isDeleted: boolean;
    protected isSelected: boolean;
    protected hasAnnotationPermissions: boolean;
    protected showAnnotations: boolean;
    protected readonly ICON_PATH = ICON_PATH;
    private isAnnotationImageLoaded: boolean;
    private isLight: boolean;

    @Input() set data(data: any) {
        this.itemData = data;
        this.setClassNames();
    }

    @Input() set qaTag(tagName: string) {
        this.tagName = tagName;
    }

    @Input() set previewImageVersion(version: string) {
        if (version) {
            this.previewImageVersionString = '&fileVersion=' + version;
        }
    }

    @Input() set annotationImageVersion(version: string) {
        if (version) {
            this.annotationImageVersionString = '&annotationsVersion=' + version;
        }
    }

    @Input() set deleted(isDeleted: boolean) {
        this.isDeleted = isDeleted;
        this.setClassNames();
    }

    @Input() set marked(isMarked: boolean) {
        this.isMarked = isMarked;
        this.setClassNames();
    }

    @Input() set selected(isSelected: boolean) {
        this.isSelected = isSelected;
        this.setClassNames();
    }

    @Input() set listDisplayType(listDisplayType: ListDisplayEnum) {
        this.isImageLoaded = false;
        if (this.listDisplayType$) {
            this.listDisplayType$.next(listDisplayType);
            this.setClassNames();
        }
    }

    @Input() set light(light: boolean) {
        this.isLight = light;
        this.setClassNames();
    }

    constructor(
        public hostReference: ElementRef,
        @Inject(LOCALE_ID)
        public localeId: string,
        public changeDetectorRef: ChangeDetectorRef,
        public permissionQuery: PermissionQuery,
        public permissionService: PermissionService,
    ) {
        super();
        this.plainName = false;
        this.isMarked = false;
        this.isDeleted = false;
        this.isSelected = false;
        this.classNames = '';
        this.listDisplayType$ = new BehaviorSubject<ListDisplayEnum>(this.listDisplayType);
        this.listDisplayType = ListDisplayEnum.list;
        this.fetchAssignments = true;
        this.useDocumentAttributes = false;
        this.useSubLine = true;
        this.hasAnnotationPermissions = true;
        this.isImageLoaded = false;
        this.isAnnotationImageLoaded = false;
        this.isLight = false;
        this.previewImageVersionString = '&fileVersion=-1';
        this.annotationImageVersionString = '&annotationsVersion=-1';
        this.tagName = '';
        this.onImageLoaded = () => {
            this.isImageLoaded = true;
            this.setClassNames();
            this.showAnnotations = this.hasAnnotationPermissions && this.isImageLoaded && (this.itemData.annotationsVersion > 0 || this.itemData.documentAnnotationsVersion > 0);
        };
        this.onImageLoadingError = () => {
            this.setClassNames();
        };
        this.onAnnotationImageLoaded = () => {
            this.isAnnotationImageLoaded = true;
            this.setClassNames();
        };
        this.data = {};
        this.deactivateListImageCache = false;
        this.showingThumbnail = true;
        this.showAnnotations = false;
    }

    public async ngAfterViewInit(): Promise<void> {
        this.hostElement = this.hostReference.nativeElement;
        this.hostElement?.setAttribute('data-qa', this.tagName);
        await this.checkAnnotationPermission();
    }

    private setClassNames(): void {
        this.changeDetectorRef.markForCheck(); // without this the class is not changed for loaded images on reload
        const listDisplayType: ListDisplayEnum = this.listDisplayType$.getValue();
        this.showingThumbnail = listDisplayType === ListDisplayEnum.fullPage || listDisplayType === ListDisplayEnum.largePreview || listDisplayType === ListDisplayEnum.smallPreview;
        this.classNames =
            'display-type-' + ListDisplayEnum[listDisplayType]
            + ((this.isMarked) ? ' marked' : '')
            + ((this.isDeleted) ? ' deleted' : '')
            + ((this.isSelected) ? ' selected' : '')
            + ((this.isImageLoaded) ? ' image-loaded' : '')
            + ((this.isAnnotationImageLoaded) ? ' annotation-image-loaded' : '')
            + ((this.isLight) ? ' light' : '');
    }

    private async checkAnnotationPermission(): Promise<void> {
        if (!(this.itemData['documentNumber'] && this.itemData['id'])) {
            return;
        }

        const documentId = this.itemData.id;
        if (this.itemData.visibilityScope === 'Trashed') {
            if (!this.permissionQuery.hasTrashedDocumentPermissionLoaded(documentId)) {
                await this.permissionService.fetchTrashedDocumentPermission(documentId);
            }

            if (!this.permissionQuery.hasTrashedDocumentPermission(documentId, 'DocumentsGetAnnotationLayer')) {
                this.hasAnnotationPermissions = false;
            }
        } else {
            if (!this.permissionQuery.hasDocumentPermissionLoaded(documentId)) {
                await this.permissionService.fetchDocumentPermission(documentId);
            }

            if (!this.permissionQuery.hasDocumentPermission(documentId, 'DocumentsGetAnnotationLayer')) {
                this.hasAnnotationPermissions = false;
            }
        }
    }
}
