import {Injectable} from '@angular/core';
import {AppService} from '../app/app.service';
import {AppQuery} from '../../queries/app.query';
import {ServerAddress} from '../../models/server-address';
import {BehaviorSubject, firstValueFrom, lastValueFrom, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {DialogService} from '../dialog/dialog.service';
import clientServerInformation from '../../../../server-information.json';
import {unsecureProtocol} from '../../constants/server/unsecure-protocol.constant';
import {secureProtocol} from '../../constants/server/secure-protocol.constant';
import {getDefaultServers} from '../../util/server/get-default-servers';
import {STATIC_CONFIGS} from '../../../configs/static.config';
import {AuthService} from '../auth/auth.service';
import {ServerInformation} from '../../api/models/server-information';
import {ServerInformation as BfaServerInformation} from '../../bfa-api/models/server-information';

@Injectable({
    providedIn: 'root'
})
export class ServerSelectionService {
    public selectedServer$: BehaviorSubject<ServerAddress | undefined>;
    public hasServerAddresses$: Observable<boolean>;
    public serverAddresses$: BehaviorSubject<Array<ServerAddress>>;
    public isCheckingForVersion: boolean;
    private previousServerAddresses: string;
    private lastSuccessfulProtocol: string;
    private lastSuccessfulUrlParts: Array<string>;
    private serverInformation: BfaServerInformation | undefined;

    constructor(
        private appQuery: AppQuery,
        private appService: AppService,
        private httpClient: HttpClient,
        private dialogService: DialogService,
        private authService: AuthService,
    ) {
        this.previousServerAddresses = '';
        this.selectedServer$ = new BehaviorSubject<ServerAddress | undefined>(undefined);
        this.serverAddresses$ = new BehaviorSubject<Array<ServerAddress>>([]);
        this.hasServerAddresses$ = this.serverAddresses$.pipe(map(serverAddresses => serverAddresses.length > 0));
        this.lastSuccessfulProtocol = '';
        this.lastSuccessfulUrlParts = [];
        this.isCheckingForVersion = environment.production;
    }

    public toggleVersionCheck(): void {
        this.isCheckingForVersion = !this.isCheckingForVersion;
    }

    public loadServers(): void {
        this.previousServerAddresses = JSON.stringify(this.appQuery.getServers());
        const serverAddresses: Array<ServerAddress> = JSON.parse(this.previousServerAddresses);
        let needToSave = false;
        // fix for already added connection without protocol
        for (const server of serverAddresses) {
            if (!this.checkForProtocol(server.url)) {
                if (!('displayUrl' in server as unknown as Record<string, unknown>) || !server.displayUrl) {
                    server.displayUrl = server.url;
                }
                server.url = this.cleanUrl(secureProtocol + server.url + '/' + environment.apiUrlParts.join('/'), false);
                needToSave = true;
            }
        }
        this.serverAddresses$.next(serverAddresses);
        this.selectedServer$.next(this.getSelectedServer());
        if (needToSave) {
            this.saveServers();
        }
    }

    public async addServer(serverAddress: ServerAddress): Promise<boolean> {
        const loadingDialog = this.dialogService.showPermanentToast('SERVER_SELECTION.CHECKING_URL_MSG');
        const exists = await this.fullCheckServerExists(serverAddress.url);
        loadingDialog.dismiss();
        if (exists) {
            serverAddress.url = this.lastSuccessfulProtocol + this.cleanUrl([serverAddress.url, ...this.lastSuccessfulUrlParts].join('/'));
            this.addServerAddressToList(serverAddress);
            this.lastSuccessfulProtocol = '';
            this.lastSuccessfulUrlParts = [];
            await lastValueFrom(this.dialogService.showDialog({ messageKey: 'SERVER_SELECTION.CONNECTION_ADD_SUCCESS', appTestTag: 'server-added-successfully' })
                .afterClosed());
            this.loadServers();
            this.selectServer(serverAddress.displayUrl, false);
        }
        return exists;
    }

    public selectServer(url: string, deselect: boolean = true): void {
        const currentSelectedServer = this.getSelectedServer();
        let selectedServer;
        if (currentSelectedServer?.displayUrl !== url) {
            const serverAddresses = this.serverAddresses$.getValue();
            for (const server of serverAddresses) {
                if (server.displayUrl === url) {
                    server.selected = true;
                    selectedServer = server;
                } else {
                    server.selected = false;
                }
            }
        } else {
            if (deselect) {
                if (currentSelectedServer) {
                    currentSelectedServer.selected = false;
                }
            } else {
                selectedServer = currentSelectedServer;
            }
        }
        this.selectedServer$.next(selectedServer);
    }

    public getServers(): Array<ServerAddress> {
        return JSON.parse(JSON.stringify(this.serverAddresses$.getValue()));
    }

    public hasServerHost(url: string): boolean {
        return this.getServers()
            .filter(serverAddress => this.cleanUrl(serverAddress.displayUrl) === this.cleanUrl(url)).length > 0;
    }

    public removeServer(name: string | undefined, displayUrl: string): void {
        this.dialogService.showConfirmDialog({
                messageKey: 'SERVER_SELECTION.CONFIRM_REMOVE',
                confirmText: 'BUTTON.OK',
                cancelText: 'BUTTON.CANCEL',
                translateKeyParameters: { name: name || displayUrl },
                appTestTag: 'remove-server',
            })
            .then(removeServer => {
                if (removeServer) {
                    this.selectedServer$.next(undefined);
                    const filteredServerAddresses = this.getServers()
                        .filter(server => server.displayUrl !== displayUrl);
                    this.serverAddresses$.next(filteredServerAddresses);
                    this.saveServers();
                }
            });
    }

    public hasChanges(): boolean {
        const servers = JSON.stringify(this.getServers());
        return this.previousServerAddresses !== servers;
    }

    public saveServers(): void {
        this.appService.updateServers(this.getServers());
    }

    public cleanUrl(url: string, removeProtocol: boolean = true): string {
        const protocol = this.extractProtocolFromUrl(url);
        if (protocol) {
            url = url.replace(protocol, '');
        }
        url = url.split('///')
            .join('/')
            .split('//')
            .join('/');
        if (url.endsWith('/')) {
            url = url.slice(0, url.length - 1);
        }
        // Add previously striped protocol
        if (!removeProtocol && protocol) {
            url = protocol + url;
        }

        // Remove internal-API path segment from server URLs, due to downward compatibility
        // @see https://amagno.atlassian.net/browse/APP-1024
        url = url.replace(new RegExp('/' + STATIC_CONFIGS.apis.internal.path + '$'), '');

        return url;
    }

    public checkForProtocol(url: string): boolean {
        return (url.includes(secureProtocol) || url.includes(unsecureProtocol));
    }

    public async loadServerInformation(url: string): Promise<BfaServerInformation | undefined> {
        try {
            const serverInformation = await firstValueFrom(this.httpClient.get<BfaServerInformation | ServerInformation>(url));
            this.serverInformation = this.mapServerInformationToBfaServerInformation(serverInformation);
        } catch (error) {
            this.serverInformation = undefined;
        }
        return this.serverInformation;
    }

    public async checkServerVersion(serverVersion: string): Promise<boolean> {
        if (!this.isCheckingForVersion) {
            return true;
        }

        const [apiVersionMajor, apiVersionMinor] = this.getMajorAndMinor(serverVersion);

        // @TODO: Temporary fix for compatibility reasons. Remove as soon as possible! See https://amagno.atlassian.net/browse/APP-838
        // This should be eliminated and change for a "feature minimum backend version requirement" system
        return apiVersionMajor === 7 || (apiVersionMajor === 6 && apiVersionMinor >= 9);
    }

    public getExpectedServerVersion(): string {
        const [clientVersionMajor, clientVersionMinor] = this.getMajorAndMinor(clientServerInformation.serverVersion);
        return clientVersionMajor + '.' + clientVersionMinor;
    }

    public async fullCheckServerExists(url: string, checkUrlParts: boolean = true): Promise<boolean> {
        const urlWithProtocol = this.cleanUrl(url, false);
        const urlWithoutProtocol = this.cleanUrl(urlWithProtocol);
        try {
            let workingUrl = '';
            // check with complete user url
            if (await this.getServerInformation(urlWithProtocol)) {
                return true;
            }
            // check for opposite protocol if url has http or https
            let protocol = this.extractProtocolFromUrl(urlWithProtocol);
            if (protocol) {
                // exchange protocol // http checking deactivated for now
                protocol = secureProtocol;//(protocol === secureProtocol) ? unsecureProtocol : secureProtocol;
                if (await this.getServerInformation(protocol + urlWithoutProtocol)) {
                    return true;
                }
            }
            // if we don't check with the url parts end the function here with false
            if (!checkUrlParts) {
                return false;
            }
            // strip protocol of user url if exists to dynamically check urls
            const urlParts = await this.checkUrlParts(urlWithProtocol);
            if (urlParts) {
                workingUrl = this.lastSuccessfulProtocol + this.cleanUrl([urlWithoutProtocol, ...urlParts].join('/'));
            }
            if (!!workingUrl) {
                if (this.serverInformation) {
                    if (!await this.checkServerVersion(this.serverInformation.serverVersion)) {
                        this.dialogService.showDialog({
                            messageKey: 'SERVER_SELECTION.ERROR.NOT_MATCHING_VERSION',
                            appTestTag: 'invalid-server-version',
                            translateKeyParameters: { version: this.getExpectedServerVersion() }
                        });
                        return false;
                    }
                    return true;
                }
            } else {
                this.dialogService.showDialog({ messageKey: 'SERVER_SELECTION.ERROR.NO_API_FOUND', appTestTag: 'connection-could-not-be-established-no-such-server' });
            }
        } catch (e) {
            console.error(e);
        }
        return false;
    }

    public addDefaultServerAddress(): void {
        const defaultServers = getDefaultServers();
        this.appService.updateServers(defaultServers);
    }

    public async getServerInformation(url: string): Promise<BfaServerInformation | undefined> {
        for (const serverInformationUrl of [
            url + '/' + STATIC_CONFIGS.apis.bfa.path + '/' + STATIC_CONFIGS.paths.serverInformation,
            url + '/' + STATIC_CONFIGS.paths.serverInformation
        ]) {
            const serverInformation = await this.checkLoadingSeverInformation(serverInformationUrl);
            if (serverInformation) {
                return serverInformation;
            }
        }
        return undefined;
    }

    public increaseLoginCount(displayUrl: string): void {
        // This action is performed to make sure that the active server addresses are used.
        // Without this, notifications aren´t updated correctly when logging in. See: https://amagno.atlassian.net/browse/APP-1268
        this.serverAddresses$.next(this.appQuery.getServers());

        const servers = this.getServers();
        const selectedServer = servers.find(server => server.displayUrl === displayUrl);
        if (selectedServer) {

            if (!('loginCount' in (selectedServer as unknown as Record<string, unknown>))) {
                selectedServer.loginCount = 0;
            }
            selectedServer.loginCount += 1;
            this.serverAddresses$.next(servers);
            this.saveServers();
        }
    }

    public extractProtocolFromUrl(url: string): string | undefined {
        const protocolRegex = /http[s]?:\/\//gm;
        const regexMatches = protocolRegex.exec(url);
        if (regexMatches) {
            return regexMatches[0];
        }
        return undefined;
    }

    connectionLost(): void {
        const selectedServer = this.appQuery.getSelectedServerUrl();
        if (selectedServer) {
            this.dialogService.showConnectionLostDialog(async () => {
                    return !!(await this.getServerInformation(selectedServer));
                })
                .then(async connectionReestablished => {
                    if (connectionReestablished) {
                        this.dialogService.showSuccess('ERROR.CONNECTION_LOST.SUCCESS');
                        this.appService.setIsDisconnected(false);
                    } else {
                        this.connectionLost();
                    }
                })
                .catch(async () => {
                    await this.authService.logoutWithoutNavigation();
                    window.location.href = '/auth/login';
                });
        }
    }

    private getMajorAndMinor(version: string): Array<number> {
        const versionParts = version.split('.');
        return versionParts.map(versionPart => Number(versionPart));
    }

    private mapServerInformationToBfaServerInformation(serverInformation: ServerInformation | BfaServerInformation): BfaServerInformation {
        return {
            apiVersion: ('apiVersion' in serverInformation) ? serverInformation.apiVersion : '',
            systemMessage: ('systemMessage' in serverInformation) ? serverInformation.systemMessage : undefined,
            registrationLinkUrl: ('registrationLinkUrl' in serverInformation) ? serverInformation.registrationLinkUrl : undefined,
            maintenanceMessage: ('maintenanceMessage' in serverInformation) ? serverInformation.maintenanceMessage : undefined,
            serverVersion: ('serverVersion' in serverInformation) ? serverInformation.serverVersion : '',
            notification: ('notification' in serverInformation) ? serverInformation.notification : undefined
        };
    }

    private async checkLoadingSeverInformation(url: string): Promise<BfaServerInformation | undefined> {
        let serverInformation: BfaServerInformation | undefined;
        if (this.checkForProtocol(url)) {
            serverInformation = await this.loadServerInformation(url);
            this.lastSuccessfulProtocol = this.extractProtocolFromUrl(url) || '';
        } else {
            serverInformation = await this.loadServerInformation(secureProtocol + url);
            this.lastSuccessfulProtocol = secureProtocol;
        }
        return serverInformation;
    }

    private async checkUrlParts(serverUrl: string): Promise<Array<string> | undefined> {
        const createAndCheckServerInformationUrls = async (protocol: string): Promise<Array<string> | undefined> => {
            const urlMiddleParts = [...environment.apiUrlParts];
            let index = urlMiddleParts.length;

            for (; index > 0; index--) {
                const urlParts = [serverUrl, ...urlMiddleParts];
                const url = protocol + this.cleanUrl(urlParts.join('/'));
                if (await this.getServerInformation(url)) {
                    this.lastSuccessfulProtocol = protocol;
                    this.lastSuccessfulUrlParts = urlMiddleParts;
                    return urlMiddleParts;
                }
                urlMiddleParts.shift();
            }
            return undefined;
        };

        // check with preferred protocol (https)
        const resultPreferredProtocol = await createAndCheckServerInformationUrls(secureProtocol);
        return resultPreferredProtocol;
        // http checking deactivated for now
        /*if (!!resultPreferredProtocol) {
            return resultPreferredProtocol;
        }

        // check secondary protocol (http)
        return createAndCheckServerInformationUrls(serverSelectionSecondaryProtocol);*/
    }

    private addServerAddressToList(serverAddress: ServerAddress): void {
        const serverAddresses = this.getServers();
        serverAddresses.push(serverAddress);
        this.serverAddresses$.next(serverAddresses);
        this.saveServers();
    }

    private getSelectedServer(): ServerAddress | undefined {
        return this.serverAddresses$
            .getValue()
            .filter(server => server.selected)
            .pop();
    }
}
