import {Injectable} from '@angular/core';
import {VaultsWithLinksService} from '../../api/services/vaults-with-links.service';
import {AuthQuery} from '../../queries/auth.query';
import {initialPermissionState, PermissionStore} from '../../stores/permission.store';
import {DialogService} from '../dialog/dialog.service';
import {MeWithLinksService} from '../../api/services/me-with-links.service';
import {MePermission} from '../../types/permissions/me-permission';
import {UserGroupsWithLinksService} from '../../api/services/user-groups-with-links.service';
import {DocumentsWithLinksService} from '../../api/services/documents-with-links.service';
import {CheckedOutDocumentsWithLinksService} from '../../api/services/checked-out-documents-with-links.service';
import {MagnetsWithLinksService} from '../../api/services/magnets-with-links.service';
import {TrashedDocumentsWithLinksService} from '../../api/services/trashed-documents-with-links.service';
import {DocumentTypeCategoriesWithLinksService} from '../../api/services/document-type-categories-with-links.service';
import {PermissionDirectiveSettings, PermissionOperator, PermissionSettings} from '../../models/permission-settings';
import {isArray} from 'lodash';
import {DocumentPermission} from '../../types/permissions/document-permission';
import {DocumentWithLinks} from '../../api/models/document-with-links';
import {CheckedOutDocumentPermission} from '../../types/permissions/checked-out-document-permission';
import {firstValueFrom, lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    constructor(
        private authQuery: AuthQuery,
        private dialogService: DialogService,
        private permissionStore: PermissionStore,
        private userGroupsWithLinksService: UserGroupsWithLinksService,
        private vaultsWithLinksService: VaultsWithLinksService,
        private meWithLinksService: MeWithLinksService,
        private documentsWithLinksService: DocumentsWithLinksService,
        private checkedOutDocumentsWithLinksService: CheckedOutDocumentsWithLinksService,
        private trashedDocumentsWithLinksService: TrashedDocumentsWithLinksService,
        private magnetsWithLinksService: MagnetsWithLinksService,
        private documentTypeCategoriesWithLinksService: DocumentTypeCategoriesWithLinksService,
    ) {
    }

    public async fetchUserGroupPermissions(userGroupId: string): Promise<void> {
        const currentPermissions = this.permissionStore.getValue().userGroupPermissions;
        if (userGroupId in currentPermissions) {
            return;
        }

        this.permissionStore.setLoading(true);
        try {
            const userGroupWithLinks = await firstValueFrom(this.userGroupsWithLinksService.UserGroupsWithLinksGet({
                userGroupId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            const userGroupPermissions = JSON.parse(JSON.stringify(currentPermissions));
            userGroupPermissions[userGroupId] = userGroupWithLinks.links.map(link => link.rel);
            this.permissionStore.update({ userGroupPermissions });
        } catch (error) {
            console.error(error);
            this.dialogService.showError('ERROR.PERMISSION.USER_GROUP_WITH_LINK_FETCHING', error as Error);
        } finally {
            this.permissionStore.setLoading(false);
        }
    }

    public async fetchVaultPermissions(vaultId: string, refresh: boolean = false): Promise<void> {
        const currentPermissions = this.permissionStore.getValue().vaultPermissions;
        if (!refresh && vaultId in currentPermissions) {
            return;
        }

        this.permissionStore.setLoading(true);
        try {
            const vaultWithLinks = await firstValueFrom(this.vaultsWithLinksService.VaultsWithLinksGet({
                vaultId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            const vaultPermissions = JSON.parse(JSON.stringify(currentPermissions));
            vaultPermissions[vaultId] = vaultWithLinks.links.map(link => link.rel);
            this.permissionStore.update({ vaultPermissions });
        } catch (error) {
            console.error(error);
            this.dialogService.showError('ERROR.PERMISSION.VAULT_WITH_LINK_FETCHING', error as Error);
        } finally {
            this.permissionStore.setLoading(false);
        }
    }

    public async fetchMePermission(): Promise<void> {
        const currentPermissions = this.permissionStore.getValue().mePermissions;
        if (currentPermissions && currentPermissions.length > 0) {
            return;
        }

        this.permissionStore.setLoading(true);
        try {
            const meWithLinks = await firstValueFrom(this.meWithLinksService.MeWithLinksGet(this.authQuery.getBearer()));
            const mePermissions: Array<MePermission> = meWithLinks.links.map(link => link.rel as MePermission);
            this.permissionStore.update({ mePermissions });
        } catch (error) {
            console.error(error);
            this.dialogService.showError('ERROR.PERMISSION.ME_FETCHING', error as Error);
        } finally {
            this.permissionStore.setLoading(false);
        }
    }

    public async fetchDocumentPermission(documentId: string, forceFetch: boolean = false): Promise<void> {
        const currentPermissions = this.permissionStore.getValue().documentPermissions;
        const newDocumentPermissions: Record<string, Array<DocumentPermission>> = JSON.parse(JSON.stringify(currentPermissions));
        if (documentId in currentPermissions && !forceFetch) {
            return;
        }
        newDocumentPermissions[documentId] = [];
        this.permissionStore.update({ documentPermissions: JSON.parse(JSON.stringify(newDocumentPermissions)) });
        this.permissionStore.setLoading(true);
        try {
            const documentsWithLinks: DocumentWithLinks = await firstValueFrom(this.documentsWithLinksService.DocumentsWithLinksGet({
                documentId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));

            newDocumentPermissions[documentId] = documentsWithLinks.links.map(link => link.rel as DocumentPermission);
        } catch (error) {
            console.error(error);
            delete newDocumentPermissions[documentId];
            this.dialogService.showError('ERROR.PERMISSION.DOCUMENT_WITH_LINK_FETCHING', error as Error);
        } finally {
            this.permissionStore.setLoading(false);
            this.permissionStore.update({ documentPermissions: JSON.parse(JSON.stringify(newDocumentPermissions)) });
        }
    }

    public async fetchCheckedOutDocumentPermission(checkedOutDocumentId: string): Promise<void> {
        const currentPermissions = this.permissionStore.getValue().checkedOutDocumentPermissions;
        if (checkedOutDocumentId in currentPermissions) {
            return;
        }

        this.permissionStore.setLoading(true);
        try {
            const checkedOutDocumentsWithLinks = await firstValueFrom(this.checkedOutDocumentsWithLinksService.CheckedOutDocumentsWithLinksGet({
                documentId: checkedOutDocumentId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            const checkedOutDocumentPermissions: Record<string, Array<CheckedOutDocumentPermission>> = JSON.parse(JSON.stringify(currentPermissions));
            checkedOutDocumentPermissions[checkedOutDocumentId] = checkedOutDocumentsWithLinks.links.map(link => link.rel as CheckedOutDocumentPermission);
            this.permissionStore.update({ checkedOutDocumentPermissions });
        } catch (error) {
            console.error(error);
            this.dialogService.showError('ERROR.PERMISSION.DOCUMENT_WITH_LINK_FETCHING', error as Error);
        } finally {
            this.permissionStore.setLoading(false);
        }
    }

    public async fetchMagnetPermission(magnetId: string): Promise<void> {
        const currentPermissions = this.permissionStore.getValue().magnetPermissions;
        if (magnetId in currentPermissions) {
            return;
        }

        this.permissionStore.setLoading(true);
        try {
            const magnetsWithLinks = await firstValueFrom(this.magnetsWithLinksService.MagnetsWithLinksGet({
                magnetId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            const magnetPermissions = JSON.parse(JSON.stringify(currentPermissions));
            magnetPermissions[magnetId] = magnetsWithLinks.links.map(link => link.rel);
            this.permissionStore.update({ magnetPermissions });
        } catch (error) {
            console.error(error);
            this.dialogService.showError('ERROR.PERMISSION.MAGNET_WITH_LINK_FETCHING', error as Error);
        } finally {
            this.permissionStore.setLoading(false);
        }
    }

    public async fetchTrashedDocumentPermission(documentId: string): Promise<void> {
        const currentPermissions = this.permissionStore.getValue().trashedDocumentPermissions;
        if (documentId in currentPermissions) {
            return;
        }

        this.permissionStore.setLoading(true);
        try {
            const trashedDocumentsWithLinks = await lastValueFrom(this.trashedDocumentsWithLinksService.TrashedDocumentsWithLinksGet({
                documentId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            const trashedDocumentPermissions = JSON.parse(JSON.stringify(currentPermissions));
            trashedDocumentPermissions[documentId] = trashedDocumentsWithLinks.links.map(link => link.rel);
            this.permissionStore.update({ trashedDocumentPermissions });
        } catch (error) {
            console.error(error);
            this.dialogService.showError('ERROR.PERMISSION.DOCUMENT_WITH_LINK_FETCHING', error as Error);
        } finally {
            this.permissionStore.setLoading(false);
        }
    }

    public clearPermissions(): void {
        this.permissionStore.update(initialPermissionState());
    }

    public removeDocumentPermissionCacheById(documentId: string): void {
        const documentPermissions = JSON.parse(JSON.stringify(this.permissionStore.getValue().documentPermissions));
        if (documentId in documentPermissions) {
            delete documentPermissions[documentId];
        }
        this.permissionStore.update({ documentPermissions });
    }

    public clearDocumentsPermissionCache(): void {
        this.permissionStore.update({ documentPermissions: {} });
    }

    public extractPermissions(permissions: PermissionSettings | PermissionDirectiveSettings | Array<PermissionSettings>): Array<PermissionSettings> {
        let permissionSettings: Array<PermissionSettings> = [];
        if ('operator' in permissions) {
            permissionSettings = permissions.permissions;
        } else {
            permissionSettings = isArray(permissions) ? permissions : [permissions];
        }
        return permissionSettings;
    }

    public extractPermissionOperator(appPermission: PermissionSettings | Array<PermissionSettings> | PermissionDirectiveSettings): PermissionOperator {
        let operator = 'AND' as PermissionOperator;
        if ('operator' in appPermission) {
            operator = appPermission.operator;
        }
        return operator;
    }

    public async fetchDocumentCategoryPermission(documentTypeCategoryId: string): Promise<void> {
        const currentPermissions = this.permissionStore.getValue().documentTypeCategoryPermissions;
        if (documentTypeCategoryId in currentPermissions) {
            return;
        }

        this.permissionStore.setLoading(true);
        try {
            const documentTypeCategoriesWithLinks = await firstValueFrom(this.documentTypeCategoriesWithLinksService.DocumentTypeCategoriesWithLinksGet({
                documentTypeCategoryId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: this.authQuery.getBearer()
            }));
            const documentTypeCategoryPermissions = JSON.parse(JSON.stringify(currentPermissions));
            documentTypeCategoryPermissions[documentTypeCategoryId] = documentTypeCategoriesWithLinks.links.map(link => link.rel);
            this.permissionStore.update({ documentTypeCategoryPermissions });
        } catch (error) {
            console.error(error);
            this.dialogService.showError('ERROR.PERMISSION.DOCUMENT_WITH_LINK_FETCHING', error as Error);
        } finally {
            this.permissionStore.setLoading(false);
        }
    }
}
