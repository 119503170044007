<div class="grid">
    <div></div>

    <div [ngClass]="{'showing': ((isShowingSmallMenu$|async)||isLargeMenuOpen), 'full-width': isLargeMenuOpen}"
         class="fab-wrapper" data-qa="action-menu-fab-wrapper">
        <div (click)="closeMenuFromMenu()" [ngClass]="{'showing': ((isShowingSmallMenu$|async)||isLargeMenuOpen)}"
             class="fab-items" data-qa="action-menu-fab-items">
            @if (isLargeMenuOpen) {
                <!-- need to use ng-content https://github.com/angular/angular/issues/14842 -->
                <ng-content></ng-content>
            }
            @if (isShowingSmallMenu$|async) {
                @if ((documentViewMode$|async) === 'Viewing') {
                    @for (item of sortedActionItems; track item.description) {
                        @if (!item.hideInSmallMenu) {
                            <button #beginnersHelpTooltip="matTooltip"
                                    (click)="item.action ? openActionMenu(item.action) : itemClickedEmitter(item)"
                                    [attr.data-qa]="location + '-fab-item-' + item.tag"
                                    [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                    [matTooltip]="item.description ?? ''"
                                    class="fab-button action-button-background"
                                    mat-icon-button
                                    matTooltipClass="action-menu-tooltip"
                                    matTooltipPosition="left"
                                    type="button">
                                @if (item.icon) {
                                    <app-custom-icon [iconUrl]="item.icon" [ngClass]="item.itemClass" class="action-menu-icon"></app-custom-icon>
                                }
                                @if (item.svgIcon) {
                                    <mat-icon [ngClass]="item.itemClass" [svgIcon]="item.svgIcon" class="action-menu-icon"></mat-icon>
                                }
                            </button>
                        }
                    }
                } @else {
                    @if (((textItems$|async) && ['TextItems', 'Viewing'].includes((documentViewMode$|async) + '')) || (documentViewMode$|async) === 'TextItems') {
                        <button #beginnersHelpTooltip="matTooltip"
                                (click)="searchForTextGlobal($event)"
                                [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                [matTooltip]="'BUTTON.SEARCH_TEXT_GLOBAL' | translate"
                                class="fab-button action-button-background"
                                data-qa="search-for-text-global"
                                mat-icon-button
                                matTooltipClass="action-menu-tooltip"
                                matTooltipPosition="left"
                                type="button">
                            <mat-icon [svgIcon]="ICONS.SEARCH_OVERALL" class="action-menu-icon"></mat-icon>
                        </button>
                        <button #beginnersHelpTooltip="matTooltip" (click)="searchForTextLocal($event)"
                                [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                [matTooltip]="'BUTTON.SEARCH_TEXT_LOCAL' | translate"
                                class="fab-button action-button-background"
                                data-qa="search-for-text-local"
                                mat-icon-button
                                matTooltipClass="action-menu-tooltip"
                                matTooltipPosition="left"
                                type="button">
                            <mat-icon [svgIcon]="ICONS.SEARCH_LOCAL" class="action-menu-icon"></mat-icon>
                        </button>
                        <button #beginnersHelpTooltip="matTooltip" (click)="shareSearchText($event)"
                                [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                [matTooltip]="'BUTTON.SHARE' | translate"
                                class="fab-button action-button-background"
                                data-qa="share-search-text"
                                mat-icon-button
                                matTooltipClass="action-menu-tooltip"
                                matTooltipPosition="left"
                                type="button">
                            <mat-icon [svgIcon]="ICONS.SENDTO" class="action-menu-icon"></mat-icon>
                        </button>
                    }
                    @if ((documentViewMode$|async) === 'Annotations') {
                        <button #beginnersHelpTooltip="matTooltip"
                                (click)="saveAction($event)"
                                [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                [matTooltip]="'BUTTON.SAVE' | translate"
                                class="fab-button action-button-background"
                                data-qa="save-annotations-action-button"
                                mat-icon-button
                                matTooltipClass="action-menu-tooltip"
                                matTooltipPosition="left"
                                type="button">
                            <mat-icon [svgIcon]="ICONS.OK"></mat-icon>
                        </button>
                        @if ((selectedAnnotation$|async) && ((selectedAnnotation$|async)?.type !== 'signatures' || (isSelectedAnnotationTemporary$|async) === true) && ((selectedAnnotation$|async)?.type !== 'stamps' || (isSelectedAnnotationTemporary$|async) === true)) {
                            <button #beginnersHelpTooltip="matTooltip"
                                    (click)="deleteAction($event)"
                                    [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                    [matTooltip]="'BUTTON.REMOVE' | translate"
                                    class="fab-button action-button-background"
                                    data-qa="delete-annotation-action-button"
                                    mat-icon-button
                                    matTooltipClass="action-menu-tooltip"
                                    matTooltipPosition="left"
                                    type="button">
                                <mat-icon [svgIcon]="ICONS.DELETEFINALLY"></mat-icon>
                            </button>
                        }
                        @if ((selectedAnnotation$|async) && (countTemporaryAnnotations$|extAsync:0) > 1 && (selectedAnnotation$|async)?.type !== 'stamps') {
                            <button #beginnersHelpTooltip="matTooltip"
                                    (click)="nextAction($event)"
                                    [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                    [matTooltip]="'BUTTON.NEXT_ANNOTATION' | translate"
                                    class="fab-button action-button-background"
                                    data-qa="next-annotation-action-button"
                                    mat-icon-button
                                    matTooltipClass="action-menu-tooltip"
                                    matTooltipPosition="left"
                                    type="button">
                                <mat-icon [svgIcon]="ICONS.NAVIGATE_NEXT" class="action-menu-icon color-blue"></mat-icon>
                            </button>
                        }
                        @if ((selectedAnnotation$|async)?.type !== 'stamps' && (isAddingStamp$|async) === false) {
                            <button #beginnersHelpTooltip="matTooltip"
                                    (click)="showAnnotationMenuAction($event)"
                                    [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                    [matTooltip]="'BUTTON.ANNOTATIONS' | translate"
                                    class="fab-button action-button-background"
                                    data-qa="show-annotation-menu-action-button"
                                    mat-icon-button
                                    matTooltipClass="action-menu-tooltip"
                                    matTooltipPosition="left"
                                    type="button">
                                <mat-icon [svgIcon]="ICONS.ANNOTATIONS" class="action-menu-icon"></mat-icon>
                            </button>
                        }
                        @if (canShowNextButton$ | async) {
                            <button #beginnersHelpTooltip="matTooltip"
                                    (click)="nextStampAction($event, true)"
                                    [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                    [matTooltip]="'BUTTON.STAMP_OK_NEXT' | translate"
                                    class="fab-button action-button-background"
                                    data-qa="next-stamp-ok-action-button"
                                    mat-icon-button
                                    matTooltipClass="action-menu-tooltip"
                                    matTooltipPosition="left"
                                    type="button">
                                <mat-icon [svgIcon]="ICONS.STEMP_OK_NEXT_PAGE" class="action-menu-icon"></mat-icon>
                            </button>
                            <button #beginnersHelpTooltip="matTooltip"
                                    (click)="nextStampAction($event, false)"
                                    [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                    [matTooltip]="'BUTTON.STAMP_CANCEL_NEXT' | translate"
                                    class="fab-button action-button-background"
                                    data-qa="next-stamp-cancel-action-button"
                                    mat-icon-button
                                    matTooltipClass="action-menu-tooltip"
                                    matTooltipPosition="left"
                                    type="button">
                                <mat-icon [svgIcon]="ICONS.STEMP_CANCEL_NEXT_PAGE" class="action-menu-icon"></mat-icon>
                            </button>
                        }
                        @if ((selectedAnnotation$|async) && (selectedAnnotation$|async)?.type !== 'signatures' && (selectedAnnotation$|async)?.type !== 'stamps') {
                            <button #beginnersHelpTooltip="matTooltip"
                                    (click)="copyAction($event)"
                                    [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                    [matTooltip]="'BUTTON.COPY_ANNOTATION' | translate"
                                    class="fab-button action-button-background"
                                    data-qa="copy-annotation-action-button"
                                    mat-icon-button
                                    matTooltipClass="action-menu-tooltip"
                                    matTooltipPosition="left"
                                    type="button">
                                <mat-icon [svgIcon]="ICONS.COPYITEM" class="action-menu-icon"></mat-icon>
                            </button>
                        }
                    }
                    @if ((documentViewMode$|async) === 'Drawing') {
                        <button #beginnersHelpTooltip="matTooltip"
                                (click)="saveAction($event)"
                                [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                                [matTooltip]="'BUTTON.CONFIRM' | translate"
                                class="fab-button action-button-background"
                                data-qa="save-drawing-action-button"
                                mat-icon-button
                                matTooltipClass="action-menu-tooltip"
                                matTooltipPosition="left"
                                type="button">
                            <mat-icon [svgIcon]="ICONS.OK"></mat-icon>
                        </button>
                    }
                }
            }
        </div>

        @if ((isShowingSmallMenu$|async) === false && !isLargeMenuOpen && (currentActionMenu$ | async) === undefined) {
            <!-- the (contextmenu) event is needed to prevent the mobile default context action -->
            <button (appTouchstartPassiveOff)="$event.preventDefault(); $event.stopPropagation(); onMouseDown()"
                    (contextmenu)="$event.preventDefault(); $event.stopPropagation(); openLargeMenu()"
                    (mousedown)="$event.preventDefault(); $event.stopPropagation(); onMouseDown()"
                    (mouseup)="$event.preventDefault(); $event.stopPropagation(); onMouseUp()"
                    (touchend)="$event.preventDefault(); $event.stopPropagation(); onMouseUp()"
                    [attr.data-qa]="location + '-fab'"
                    appBetterEvents
                    class="fab-button floating-button action-button-background-red"
                    mat-icon-button
                    type="button">
                <mat-icon [svgIcon]="ICONS.DREIPUNKTE" class="action-menu-icon"></mat-icon>
            </button>
        } @else {
            <button #beginnersHelpTooltip="matTooltip"
                    (click)="closeMenu()"
                    [attr.data-qa]="location + '-fab-close'"
                    [matTooltipDisabled]="!(preferences$ | async)?.showBeginnersHelp"
                    [matTooltip]="(documentViewMode$|async) === 'Drawing' ? ('BUTTON.CANCEL' | translate) : ''"
                    class="fab-button floating-button"
                    color="primary" mat-icon-button
                    matTooltipClass="action-menu-tooltip"
                    matTooltipPosition="left"
                    type="button">
                <mat-icon [svgIcon]="ICONS.CLOSE" class="action-menu-icon"></mat-icon>
            </button>
        }
        @if (isLargeMenuOpen) {
            <app-resizable-card [cardId]="cardId" [location]="location" [startSizeByContent]="true"></app-resizable-card>
        }
    </div>
</div>
<ng-container #cardContainer></ng-container>
